<template>
  <b-row>
    <b-col sm="12">
      <b-card>
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Precios de Productos</h4>
            <div class="small text-muted">Administrador de listas de precios de cada proveedor</div>
          </b-col>                            
        </b-row>
      </b-card>

      <b-card v-if="!statusEditPrice">
        <b-row>
          <b-col sm="10">
            <b-row>                                                       
              <b-col sm="3">
                <v-select :options="arr.filters.suppliers" v-model="filters.suppliers" placeholder="Proveedores" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>  
              <b-col sm="2">
                <b-button variant="outline-dark" @click="loadSuppliers()" size="sm">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>              
            </b-row>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="3" :style="{'display': statusEditPrice ? 'none': 'block'}">
          <b-card id="cost-tree-suppliers">
            <vue-tree-list
              @click="selectedItem"              
              :model="suppliersTree"
              v-bind:default-expanded="false"              
            >                      
              <template v-slot:leafNameDisplay="slotProps">                
                <span>
                  &nbsp;&nbsp;{{ slotProps.model.name }}
                </span>
              </template>              

            </vue-tree-list>                     

            <div v-if="suppliers">
              <b-alert show v-if="!suppliers.length" variant="warning">
                No se encontraron proveedores con listas de precios cargadas
              </b-alert>
            </div>
          </b-card>          
        </b-col>        
        <b-col :lg="statusEditPrice ? 12 : 9">
          <b-card>
            <b-row v-if="table.mostrarFiltros">
              <b-col sm="3">
                <b-form-group>
                  <b-input-group>
                    <b-form-input type="text" size="sm" placeholder="Ingrese código, nombre o referencia externa" v-model="filterQuery.text" v-on:keyup.enter.native="selectedItem(filterQuery, true)"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>   
              <b-col sm="2">
                <v-select :options="arr.select.haveReferenceExternal" v-model="filterQuery.haveReferenceExternal" placeholder="Referencia Externa" :multiple="false" :select-on-tab="true"></v-select>
              </b-col>
              <b-col sm="2">
                <v-select :options="arr.select.linkedProduct" v-model="filterQuery.linkedProduct" placeholder="Producto Vinculado" :multiple="false" :select-on-tab="true"></v-select>
              </b-col>
              <b-col sm="2">
                <v-select :options="arr.select.notImported" v-model="filterQuery.notImported" placeholder="No Importados" :multiple="false" :select-on-tab="true"></v-select>
              </b-col>
              <b-col sm="2">
                <b-form-select :options="arr.select.lastUpdate" v-model="filterQuery.lastUpdate"></b-form-select>                
              </b-col>                                                        
              <b-col sm="1">
                <b-button variant="dark" @click="selectedItem(filterQuery, true)" size="sm" title="Filtrar">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>                                
                </b-button>
              </b-col>
              <b-col sm="12">
                <hr>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="8">
                <b>{{this.itemSelected}}</b>
                <span class="crud-price-list-suppliers-download">
                  <b-link v-if="itemSelected" title="Descargar lista de precios en formato CSV para el proveedor seleccionado">
                    <b-icon icon="download" animation="throb" variant="success" @click="prepareExport()"></b-icon>
                  </b-link>                                                                               
                </span>
                <span v-if="statusEditPrice" class="ml-3">
                  <b-link href="javascript:void(0);" @click="endChangePrice()" title="Click para finalizar edición">
                    <b-badge variant="warning">
                      FINALIZAR MODO EDICIÓN DE PRECIOS MÚLTIPLE
                    </b-badge>
                  </b-link>
                </span>
              </b-col>

              <b-col sm="4" v-if="!statusEditPrice" class="pull-right">
                <span>             
                  <b-dropdown right 
                              text="Opciones" 
                              variant="primary" 
                              size="sm"
                              class="btn-pill float-right ml-2" 
                              v-b-tooltip.hover 
                              title="Opciones de Edición" 
                              v-if="access.elements.changeProductsSale">                  
                    <b-dropdown-item @click="addProduct()">
                      <b-icon icon="plus"></b-icon> Agregar Producto
                    </b-dropdown-item>

                    <b-dropdown-divider v-if="hasShowPriceSales"></b-dropdown-divider>
                    <b-dropdown-item @click="selectPriceSalesDefault()" v-if="hasShowPriceSales">
                      <b-icon icon="currency-dollar"></b-icon> Precio de Venta por Defecto
                    </b-dropdown-item>

                    <b-dropdown-divider v-if="itemSelected"></b-dropdown-divider>
                    <b-dropdown-item @click="initChangePrice()" v-if="itemSelected">
                      <b-icon icon="pencil-square"></b-icon> Edición Múltiple
                    </b-dropdown-item>

                    <b-dropdown-divider v-if="itemSelected"></b-dropdown-divider>
                    <b-dropdown-item @click="showEditPriceLote()" v-if="itemSelected" title="Modifica el precio de lista de todos los productos filtrados">
                      <b-icon icon="percent"></b-icon> Edición general de precios
                    </b-dropdown-item>
                    <b-dropdown-item @click="showEditDiscountLote()" v-if="itemSelected" title="Agrega el mismo descuento a todos los productos filtrados">
                      <b-icon icon="segmented-nav"></b-icon> Edición general de descuentos
                    </b-dropdown-item>                                    

                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="openImportCSV()">                    
                      <b-icon icon="file-earmark-excel-fill"></b-icon> Importaciones
                    </b-dropdown-item>									
                  </b-dropdown>
                </span>
                <span v-if="table.items && table.items.length" class="pull-right">                  
                  <span sm="12" v-if="!table.mostrarFiltros">
                    <b-button type="button" variant="outline-dark" size="sm" class="btn-pill" @click="showFilters()" v-b-tooltip.hover title="Mostrar filtros">
                      <i class="fa fa-filter"></i> 
                    </b-button>
                  </span>
                  <span v-else>
                    <b-button type="button" variant="outline-dark" size="sm" class="btn-pill" v-if="table.mostrarFiltros" @click="hideFilters()" v-b-tooltip.hover title="Ocultar filtros">
                      <i class="fa fa-window-close"></i>
                    </b-button>
                  </span>                  
                </span>                                
              </b-col>
              <b-col sm="4" v-if="statusEditPrice" class="pull-right">
                <span>             
                  <b-dropdown right 
                              text="Opciones" 
                              variant="warning" 
                              size="sm"
                              class="btn-pill float-right ml-2" 
                              v-b-tooltip.hover 
                              title="Opciones de Edición" 
                              v-if="access.elements.changeProductsSale">                  
                                        
                    <b-dropdown-item @click="selectPriceSalesDefault()" v-if="hasShowPriceSales">
                      <b-icon icon="currency-dollar"></b-icon> Precio de Venta por Defecto
                    </b-dropdown-item>
                    <b-dropdown-divider v-if="hasShowPriceSales"></b-dropdown-divider>

                    <b-dropdown-item @click="showEditPriceLote()" v-if="itemSelected" title="Modifica el precio de lista de todos los productos filtrados">
                      <b-icon icon="percent"></b-icon> Edición general de precios
                    </b-dropdown-item>
                    <b-dropdown-item @click="showEditDiscountLote()" v-if="itemSelected" title="Agrega el mismo descuento a todos los productos filtrados">
                      <b-icon icon="segmented-nav"></b-icon> Edición general de descuentos
                    </b-dropdown-item>

                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="endChangePrice()" title="Finaliza el modo de edición de precios múltiples">
                      <b-icon icon="x-square"></b-icon> Finalizar edición múltiple
                    </b-dropdown-item>              
                  </b-dropdown>
                </span>
                <span v-if="table.items && table.items.length" class="pull-right">                  
                  <span sm="12" v-if="!table.mostrarFiltros">
                    <b-button type="button" variant="outline-dark" size="sm" class="btn-pill" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                      <i class="fa fa-filter"></i> 
                    </b-button>
                  </span>
                  <span v-else>
                    <b-button type="button" variant="outline-dark" size="sm" class="btn-pill" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                      <i class="fa fa-window-close"></i>
                    </b-button>
                  </span>                  
                </span>                  
              </b-col>                
              <b-col sm="12" class="mt-2">

                <b-table class="mb-0"
                      style="min-height:250px;"
                      responsive
                      head-variant="dark"
                      :hover="true"
                      :small="true"
                      :fixed="false"
                      :items="table.items"
                      :fields="table.fields"
                      :filter="table.filter"
                      :current-page="table.currentPage"
                      :per-page="table.perPage"
                      selectable
                      select-mode="multi"                                              
                      :busy="table.isBusy"                     
                      v-if="(table.items && table.items.length) || table.isBusy">   
                    
                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template> 

                  <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Cargando...</strong>
                    </div>
                  </template>

                  <template v-slot:cell(code)="data">
                    <span :title="data.item.code">
                      <b-icon icon="exclude" 
                              variant="warning"
                              class="mr-2"
                              title="Producto no importado"
                              v-if="data.item.not_imported"
                              ></b-icon>
                      {{data.item.code}}
                    </span>                    
                  </template>

                  <template v-slot:cell(name)="data">
                    <span :id="'popover-name-' + data.item.id" style="cursor:pointer;">                    
                      {{data.item.name}}
                    </span>
                    <b-icon icon="exclamation-diamond-fill" 
                            class="rounded-circle" 
                            variant="danger" 
                            title="No tiene relación con ningún Producto de Venta"
                            v-if="!data.item.products_id">
                    </b-icon>

                    <b-popover custom-class="popover-name-cost-suplliers-products" :target="'popover-name-' + data.item.id" variant="warning" triggers="hover">                            
                      <div v-html="showParseReferenceExternal(data.item)"></div>
                    </b-popover>                         
                  </template>

                  <template v-slot:cell(category)="data">
                    <span :title="data.item.category + ' > ' + data.item.subcategory">
                      {{data.item.category}} > {{data.item.subcategory}}
                    </span>
                  </template>

                  <template v-slot:cell(unit_measurement)="data">   
                    <div :title="data.item.unit_measurement_purchase && data.item.unit_measurement_purchase.name">
                      {{ data.item.unit_measurement_purchase.reference }}
                    </div>
                  </template>

                  <template v-slot:cell(raise)="data">   
                    <div v-html="showRaisePrice(data.item)"></div>                      
                  </template>

                  <template v-slot:cell(price_list)="data">
                    <div v-if="statusEditPrice && data.item.default">
                      <b-input-group size="sm" class="crud-products-customers-other">
                        <b-input-group-prepend is-text>{{getCurrencySymbol}}</b-input-group-prepend>                
                        <b-form-input aria-label="Precio de Lista" title="Precio de Lista" v-model="crud.prodTMP[data.index]['price_list']" type="number" step="0.01" min="0" v-on:keyup.enter.native="savePrice(crud.prodTMP[data.index])"></b-form-input>                      
                        <b-input-group-append>
                          <b-button variant="success" title="Confirmar" @click="savePrice(crud.prodTMP[data.index])">
                            <b-icon icon="check2" />
                          </b-button>
                        </b-input-group-append>                
                      </b-input-group>
                    </div>
                    <div v-else>                    
                      <b>
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency }).format(data.item.price_list)}}                                            
                      </b>
                    </div>
                  </template>

                  <template v-slot:cell(cost)="data">
                    <span v-if="data.item.price_list != data.item.price_cost">
                      <span :id="'popover-price-' + data.item.id" style="cursor:pointer;">
                        <b>
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency }).format( data.item.price_cost )}}                      
                        </b>
                      </span>

                      <b-popover custom-class="popover-price-cost-suplliers-products" :target="'popover-price-' + data.item.id" variant="warning" triggers="hover">                            
                        <div v-html="showParseExtras(data.item)"></div>                      
                      </b-popover>                     
                    </span>                   
                  </template>    

                  <template v-slot:cell(default)="data">
                    <b-badge v-if="data.item.default" variant="dark">
                      SI
                    </b-badge>
                  </template>

                  <template v-slot:cell(last_update)="data">
                    <span v-if="diffLastUpdate(data.item) <= 7" variant="dark" v-b-tooltip.hover :title="'Última Atualización: ' + diffLastUpdate(data.item) + ' día/s'">
                      <b-icon icon="circle-fill" font-scale="1" variant="success"></b-icon>
                    </span>    
                    <span v-if="diffLastUpdate(data.item) > 7 && diffLastUpdate(data.item) <= 15" variant="dark" v-b-tooltip.hover :title="'Última Atualización: ' + diffLastUpdate(data.item) + ' día/s'">
                      <b-icon icon="circle-fill" font-scale="1"  variant="warning" style="color: #c4d126 !important;"></b-icon>
                    </span>                                         
                    <span v-if="diffLastUpdate(data.item) > 15 && diffLastUpdate(data.item) <= 30" variant="dark" v-b-tooltip.hover :title="'Última Atualización: ' + diffLastUpdate(data.item) + ' día/s'">
                      <b-icon icon="circle-fill" font-scale="1"  variant="warning"></b-icon>
                    </span>
                    <span v-if="diffLastUpdate(data.item) > 30" variant="dark" v-b-tooltip.hover :title="'Última Atualización: ' + diffLastUpdate(data.item) + ' día/s'">
                      <b-icon icon="circle-fill" animation="throb" font-scale="1" variant="danger"></b-icon>
                    </span>                    
                  </template>     

                  <template v-slot:cell(prices)="data">                                        
                    <div v-if="data.item.product.prices.length > 0">
                      <div v-for="element in data.item.product.prices" :key="element.id">
                        <div v-if="element.list && element.list.id == showPriceSalesDefault" style="cursor:pointer;" @click="setPricesProducts(data.item)">
                          <span :id="'popover-price-sales-' + element.id">
                            <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:element.list.currency.code}).format(element.amount_total)}}</b>
                            <span v-if="element.unit_measurement_result"> {{element.unit_measurement_result.reference}}</span>
                            <span v-if="parseFloat(element.desc) > 0" class="ml-1">
                              <i>({{Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits:2}).format(parseFloat(element.desc)/100)}})</i>
                            </span>
                          </span>
                          <b-popover custom-class="popover-price-cost-suplliers-products" :target="'popover-price-sales-' + element.id" variant="secondary" triggers="hover">                            
                            <div v-html="showParsePriceSales(element)"></div>                      
                          </b-popover>                       
                        </div>
                      </div>
                    </div>
                  </template>                                                 
                                
                  <template v-slot:cell(prices_iva)="data">   
                    <div v-if="data.item.product.prices.length > 0">
                      <div v-for="element in data.item.product.prices" :key="element.id">
                        <div v-if="element.list && element.list.id == showPriceSalesDefault" style="cursor:pointer;" @click="setPricesProducts(data.item)">
                          <span :id="'popover-price-sales-iva-' + element.id">
                            <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:element.list.currency.code}).format(element.amount_total_iva)}}</b>
                            <span v-if="element.unit_measurement_result"> {{element.unit_measurement_result.reference}}</span>
                            <span v-if="parseFloat(element.desc) > 0" class="ml-1">
                              <i>({{Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits:2}).format(parseFloat(element.desc)/100)}})</i>
                            </span>
                          </span>

                          <b-popover custom-class="popover-price-cost-suplliers-products" :target="'popover-price-sales-iva-' + element.id" variant="secondary" triggers="hover">                            
                            <div v-html="showParsePriceIvaSales(element)"></div>                      
                          </b-popover> 
                        </div>
                      </div>
                    </div>
                  </template>

                  <template v-slot:cell(f_action)="data">
                    <b-dropdown right text="" size="sm" variant="outline-dark" class="pull-right crud-price-list-suppliers-table-action">                      
                      <b-dropdown-item v-if="data.item.reference_external" @click="sincroPrice(data.item)" style="background-color: #63c2de;">
                        <i class="fa fa-repeat ml-0 mr-0"></i> Sincronizar Precios
                      </b-dropdown-item>                         
                      <b-dropdown-divider v-if="data.item.reference_external"/>                                                    

                      <b-dropdown-item @click="showHistory(data.item)">
                        <i class="fa fa-history ml-0 mr-0"></i> Historial
                      </b-dropdown-item>
                      <b-dropdown-item v-if="data.item.products_id" @click="showCompare(data.item)">
                        <i class="fa fa-columns ml-0 mr-0"></i> Comparar Precios
                      </b-dropdown-item>
                      
                      <b-dropdown-header v-if="access.elements.changeProductsSale">Acciones</b-dropdown-header>
                      <b-dropdown-item v-if="!data.item.default && access.elements.changeProductsSale" @click="setDefault(data.item)">
                        <i class="fa fa-dot-circle-o ml-0 mr-0"></i> Marcar Por Default
                      </b-dropdown-item>                                                 
                      <b-dropdown-item v-if="data.item.default && access.elements.changeProductsSale" @click="editProduct(data.item)">
                        <i class="fa fa-clone ml-0 mr-0"></i> Editar Producto
                      </b-dropdown-item>                      
                      <b-dropdown-divider v-if="access.elements.changeProductsSale" />
                      <b-dropdown-item @click="deleteProduct(data.item)" v-if="access.elements.changeProductsSale" style="background-color: #f86c6b;">
                        <i class="fa fa-trash ml-0 mr-0"></i> Eliminar Producto
                      </b-dropdown-item>                                            

                      <b-dropdown-divider v-if="data.item.history.length"/>                   
                      <b-dropdown-item v-if="data.item.history.length" @click="undoPrice(data.item)" style="background-color: #ffc107;">
                        <i class="fa fa-undo ml-0 mr-0"></i> Deshacer precio actual
                      </b-dropdown-item>                                                                            
                    </b-dropdown>
                  </template>
                </b-table>
                <b-alert show variant="info" v-else>
                  No hay productos para mostrar
                </b-alert>   

              </b-col>
              <b-col class="mt-4">
                <nav v-if="table.tablePaginate">
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="table.tablePaginate.total"
                                :per-page="table.tablePaginate.per_page"
                                v-model="table.currentPage"
                                @input="selectedItem(filterQuery, true)" />
                </nav>                
              </b-col>              
            </b-row>
          </b-card>
        </b-col>
      </b-row> 

      <b-row>
        <b-col lg="12">  
          <b-card>
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>                        
              </b-col>                      
            </b-row>
          </b-card>
        </b-col>
      </b-row>    

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD IMPORTACIONES -->
      <b-modal  v-model="modal.formImport.active"
                header-bg-variant="dark"
                header-text-variant="white"
                size="lg">
                
        <div slot="modal-header">          
          <Minimize />
          {{this.modal.formImport.title}}
        </div>
        
        <b-tabs pills card vertical>
          <b-tab title="Lista de Precios" @click="crud.formImport.type='import-costs-suppliers-products'" active >
            <b-card-text>
              <b-row>       
                <b-col md="12" class="mb-2">
                  <b-alert show variant="info">
                    <h4 class="alert-heading">Importación de Lista de Precio por Proveedor</h4>
                    
                    <div>
                      <b-icon icon="arrow-right-short"/> El sistema importará todos los registros cargados en el archivo.
                    </div>
                    <div>
                      <b-icon icon="arrow-right-short"/> Esta importación solo permite agregar <b>Productos de Proveedores</b>.
                    </div>                      
                    <div>
                      <b-icon icon="arrow-right-short"/> El <b>código de producto ingresado</b>, se vinculará con el <b>Producto Existente</b>, sino crea un <b>Producto Nuevo</b>.
                    </div>                    
                    <div>
                      <b-icon icon="arrow-right-short"/> Si el <b>producto inactivado</b> tiene una relación unica con un producto de venta, tambien lo <b>inactivará</b>.
                    </div>                                                            
                    <div>
                      <b-icon icon="arrow-right-short"/> En caso que el <b>producto de venta</b> tenga varios proveedores, solo se <b>inactivará</b> el producto para ese proveedor
                    </div>                                                                                
                    <div>
                      <b-icon icon="arrow-right-short"/> Es posible cargar una <b>utilidad diferencial</b> por <b>producto</b> para una <b>lista de precios</b>.
                    </div>                      

                    <hr>

                    <div class="mb-0">
                      Descargue el <a href="javascript:void(0);" @click="goDownloadFormat('examples-imports/costs/csv-format-costs-suppliers-products.csv')"><b>formato de ejemplo</b></a> para esta importación.
                    </div>                                                                  
                  </b-alert>    
                </b-col>
                <b-col md="12" class="mb-2">
                  <FindObject type="suppliers" 
                              @select-object="loadSuppliersImportCSV($event)" 
                              :valueID="crud.formImport.suppliers_id"
                              :where="conditionSuppliersActive"
                              render="search"/>                  
                </b-col>
                <b-col md="12" class="mb-2">                  
                  <b-form-group label="Archivo CSV [Lista de Precios]">                
                    <b-form-file v-model="crud.formImport.file"
                                  size="sm"
                                  :state="Boolean(crud.formImport.file)"
                                  placeholder="Elija un archivo o sueltelo aquí..."
                                  drop-placeholder="Suelta el archivo aquí...">
                    </b-form-file>                    
                  </b-form-group>         
                </b-col> 
                <b-col md="12" class="mb-2">                  
                  <b-form-group label="Tipo de Importación">                
                    <b-form-select v-model="crud.formImport.typeImport" :options="arr.select.typeImport" @input="changeTypeImportCSV()"></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>              
            </b-card-text>
          </b-tab>
        </b-tabs>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formImport.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="importCSV()">Importar</b-button>          
        </div>

      </b-modal>

      <!-- HISTORIAL -->
      <b-modal v-model="modal.formHistory.active"
               header-bg-variant="dark"
               header-text-variant="white"
               size="lg">

        <div slot="modal-header">
          <Minimize />
          {{this.modal.formHistory.title}}
        </div>

        <b-row>
          <b-col lg="12">
            <b>{{this.itemSelectedHistory.toUpperCase()}}</b>
          </b-col>
          <b-col lg="12" class="cost-supplier-products-history-overflow">
            <b-table class="mb-0" 
                  responsive
                  head-variant="dark"
                  :hover="true"
                  :small="true"
                  :fixed="false"
                  :items="tableHistory.items"
                  :fields="tableHistory.fields"
                  :filter="tableHistory.filter"
                  :current-page="tableHistory.currentPage"
                  :per-page="tableHistory.perPage"
                  :busy="tableHistory.isBusy"                     
                  v-if="tableHistory.items.length || tableHistory.isBusy">   
                
              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"                    
                  :key="field.key"
                  :style="{ width: field.width }"
                >
              </template> 

              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Cargando...</strong>
                </div>
              </template>

              <template v-slot:cell(date)="data">
                {{moment(data.item.date).format('DD/MM/YYYY')}}
              </template>

              <template v-slot:cell(unit_measurement)="data">   
                <div :title="data.item.unit_measurement_purchase && data.item.unit_measurement_purchase.name">
                  {{ data.item.unit_measurement_purchase.reference }}
                </div>
              </template>

              <template v-slot:cell(price_list)="data">
                <b>
                  {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency }).format(data.item.price_list)}}                      
                </b>
              </template>

              <template v-slot:cell(extra)="data">
                <span v-if="data.item.additional">
                  + {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency }).format(data.item.additional)}}                                          
                </span>                                        
              </template>           

              <template v-slot:cell(percentage_discount)="data">
                <span v-if="getDiscount(data.item,1)"> -{{getDiscount(data.item,1)}} </span>
                <span v-if="getDiscount(data.item,2)"> -{{getDiscount(data.item,2)}} </span>
                <span v-if="getDiscount(data.item,3)"> -{{getDiscount(data.item,3)}} </span>
                
                <span v-if="getDiscount(data.item,1) || getDiscount(data.item,2) || getDiscount(data.item,3)">
                  %
                </span>
              </template>

              <template v-slot:cell(additional)="data">
                <span v-if="getAdditional(data.item,1)"> +{{getAdditional(data.item,1)}} </span>
                <span v-if="getAdditional(data.item,2)"> +{{getAdditional(data.item,2)}} </span>                    
                
                <span v-if="getAdditional(data.item,1) || getAdditional(data.item,2)">
                  %
                </span>
              </template>

              <template v-slot:cell(extra_1)="data">
                <span v-if="data.item.additional_1">
                  + {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency }).format(data.item.additional_1)}}                                          
                </span>                                        
              </template>           

              <template v-slot:cell(cost)="data">
                <b>
                  {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency }).format( data.item.price_cost )}}                      
                </b>
              </template>    
            </b-table>
            <b-alert show variant="info" v-else>
              No se encontró el historial
            </b-alert>               
          </b-col>
        </b-row>  

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="closeHistory()">Cerrar</b-button>
        </div>                     
      </b-modal>

      <!-- CONVERSION -->
      <b-modal v-model="modal.formConvert.active"
               header-bg-variant="dark"
               header-text-variant="white"
               size="lg">

        <div slot="modal-header">
          <Minimize />
          {{this.modal.formConvert.title}}
        </div>

        <b-tabs card>
          <b-tab no-body title="General">
            <b-row>
              <b-col lg="12" v-if="this.itemSelectedConvert">
                <b>{{this.itemSelectedConvert.toUpperCase()}}</b>
              </b-col>
              <b-col lg="12">
                <b-form-radio-group
                  v-model="crud.formConvert.type"
                  :options="arr.typeConvert"
                  class="mb-3"
                  value-field="item"
                  text-field="name"
                  @input="changeTypeConvert()"  
                  :disabled="!crud.formConvert.changeType"            
                ></b-form-radio-group>            
              </b-col>
              <b-col lg="12" v-if="!this.itemSelectedConvert">
                <FindObject type="suppliers" 
                            @select-object="loadSuppliersAddProduct($event)" 
                            tag="Proveedor"
                            :valueID="crud.formConvert.suppliers_id"
                            :where="conditionSuppliersActive"
                            render="search"/>  
              </b-col>              
              <b-col lg="12">
                <b-row>
                  <b-col lg="4">
                    <b-form-group label="Código">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.formConvert.code"
                                    placeholder="Ingresar un código">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col lg="8">
                    <b-form-group label="Nombre">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.formConvert.name"
                                    placeholder="Ingresar un nombre">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group label="Categoría">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.formConvert.category"
                                    placeholder="Ingresar una categoría">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group label="Subcategoría">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.formConvert.subcategory"
                                    placeholder="Ingresar una subcategoría">
                      </b-form-input>
                    </b-form-group>
                  </b-col>         
                  <b-col lg="6" v-if="hasLines">
                    <b-form-group label="Línea">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.formConvert.line"
                                    placeholder="Ingresar una linea">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" v-if="hasLines">
                    <b-form-group label="Sublínea">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.formConvert.subline"
                                    placeholder="Ingresar una subline">
                      </b-form-input>
                    </b-form-group>
                  </b-col>                           
                  <b-col lg="12">
                    <b-form-group label="Código de Barra">
                      <b-form-input type="text"
                                    size="sm"
                                    v-model="crud.formConvert.barcode"
                                    placeholder="Ingresar un código de barra">
                      </b-form-input>
                    </b-form-group>          
                  </b-col>     
                  <b-col lg="12">
                    <hr>
                  </b-col>
                  <b-col lg="12">
                    <b-tabs pills card>
                      <b-tab no-body title="Manual" active>
                        <b-form-group label="Referencia Externa" description="Es un código único del producto utilizado para actualizar precios desde intergaciones vía API">
                          <b-form-input type="text"
                                        size="sm"
                                        v-model="crud.formConvert.reference_external"
                                        placeholder="Ingresar una referencia externa">
                          </b-form-input>
                        </b-form-group>  
                      </b-tab>
                      <b-tab no-body title="Base de datos">
                        <FindObject type="costsSuppliersProductsSupport"
                                    tag="Referencia Externa"
                                    description="Seleccione la referencia a un producto externo desde base de datos"
                                    @select-object="loadReferenceExternal($event)"
                                    display="reference"
                                    referenceSTR="reference"
                                    :valueSTR="crud.formConvert.reference_external"                                    
                                    render="search"/>
                      </b-tab>
                    </b-tabs>
                  </b-col>                                                                       
                </b-row>
              </b-col>            
            </b-row>
          </b-tab>
          <b-tab no-body title="Precio">        
            <b-row>
              <b-col lg="5">
                <b-form-group :label="'Precio de Lista ($)'">
                  <b-form-input type="number"
                                size="sm"
                                step="0.01"
                                v-model="crud.formConvert.price_list"
                                placeholder="Precio de Lista">
                  </b-form-input>            
                </b-form-group>
              </b-col>                  
            </b-row>
          </b-tab>
          <b-tab no-body title="+ ($)">
            <b-row>
              <b-col lg="12">
                <b-form-group :label="'Adicional ($)'" description="Se calcula sobre el precio de lista">
                  <b-form-input type="number"
                                size="sm"
                                v-model="crud.formConvert.additional"
                                placeholder="Adicional">
                  </b-form-input>            
                </b-form-group>
              </b-col>  
            </b-row>            
          </b-tab>
          <b-tab no-body title="- (%)">
            <b-row>
              <b-col lg="12">
                <b-row>
                  <b-col lg="4">
                    <b-form-group :label="'Descuento 1 (%)'">
                      <b-form-input type="number"
                                    size="sm"
                                    v-model="crud.formConvert.percentage_discount"
                                    placeholder="Descuento">
                      </b-form-input>            
                    </b-form-group>
                  </b-col>
                  <b-col lg="4">
                    <b-form-group :label="'Descuento 2 (%)'">
                      <b-form-input type="number"
                                    size="sm"
                                    v-model="crud.formConvert.percentage_discount_1"
                                    placeholder="Descuento">
                      </b-form-input>            
                    </b-form-group>
                  </b-col>
                  <b-col lg="4">
                    <b-form-group :label="'Descuento 3 (%)'">
                      <b-form-input type="number"
                                    size="sm"
                                    v-model="crud.formConvert.percentage_discount_2"
                                    placeholder="Descuento">
                      </b-form-input>            
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>              
            </b-row>
          </b-tab>
          <b-tab no-body title="+ (%) + ($)">
            <b-row>
              <b-col lg="12">
                <b-row>                      
                  <b-col lg="6">
                    <b-form-group :label="'Adiccional 1 (%)'">
                      <b-form-input type="number"
                                    size="sm"
                                    v-model="crud.formConvert.additional_percentage"
                                    placeholder="Adiccional">
                      </b-form-input>            
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group :label="'Adiccional 2 (%)'">
                      <b-form-input type="number"
                                    size="sm"
                                    v-model="crud.formConvert.additional_percentage_1"
                                    placeholder="Adiccional">
                      </b-form-input>            
                    </b-form-group>
                  </b-col>              
                  <b-col lg="12">
                    <b-form-group :label="'Adicional ($)'" description="Se calcula sobre el precio final [precio de lista + adiccional ($) - descuentos (%) + adiccional (%)]">
                      <b-form-input type="number"
                                    size="sm"
                                    v-model="crud.formConvert.additional_1"
                                    placeholder="Adicional">
                      </b-form-input>            
                    </b-form-group>
                  </b-col>      
                </b-row>
              </b-col> 
            </b-row>
          </b-tab>  
          <b-tab no-body title="Atributos" v-if="hasMeasurement">
            <b-row>
              <b-col md="12">
                <b-form-group label="Profundidad" description="Ingrese la medida en cm">
                  <b-form-input type="number"
                                size="sm"
                                v-model="crud.formConvert.depth"
                                required
                                placeholder="Ingresar una profundidad">
                  </b-form-input>
                </b-form-group>
              </b-col>    
              <b-col md="12">
                <b-form-group label="Largo" description="Ingrese la medida en cm">
                  <b-form-input type="number"
                                size="sm"
                                v-model="crud.formConvert.long"
                                required
                                placeholder="Ingresar un largo">
                  </b-form-input>
                </b-form-group>
              </b-col>    
              <b-col md="12">
                <b-form-group label="Alto" description="Ingrese la medida en cm">
                  <b-form-input type="number"
                                size="sm"
                                v-model="crud.formConvert.height"
                                required
                                placeholder="Ingresar un alto">
                  </b-form-input>
                </b-form-group>
              </b-col> 
              <b-col md="12">
                <b-form-group label="Peso" description="Ingrese el peso en kg">
                  <b-form-input type="number"
                                size="sm"
                                v-model="crud.formConvert.weight"
                                required
                                placeholder="Ingresar un peso">
                  </b-form-input>
                </b-form-group>
              </b-col>                   
            </b-row>
          </b-tab>                  
          <b-tab no-body title="U.Medidas">
            <b-row>
              <b-col lg="8">
                <FindObject type="unitMeasurement" 
                            tag="Unidad de Medida de Compra"
                            @select-object="loadUnitMeasurementPurchase($event)" 
                            :valueID="crud.formConvert.unit_measurement_purchase_id"
                            render="search"/>                       
              </b-col>              
              <b-col lg="8">
                <FindObject type="unitMeasurement" 
                            tag="Unidad de Medida de Venta"
                            @select-object="loadUnitMeasurementSale($event)" 
                            :valueID="crud.formConvert.unit_measurement_sale_id"
                            render="search"/>                       
              </b-col>
              <b-col lg="4" v-if="crud.formConvert.unit_measurement_purchase_id != crud.formConvert.unit_measurement_sale_id">
                <b-form-group label="Variable" description="Variable de Conversión">
                  <b-form-input type="number"
                                size="sm"
                                step="0.01"
                                v-model="crud.formConvert.unit_measurement_sale_conversion"
                                placeholder="Ingrese un valor">
                  </b-form-input>            
                </b-form-group>
              </b-col>                  
            </b-row>
          </b-tab>            
          <b-tab no-body title="Fiscal" v-if="isProcessSales">
            <b-row>
              <b-col lg="12">
                <FindObject render="search"
                            type="erpIvaConditions" 
                            @select-object="loadIvaConditions($event)" 
                            :valueID="crud.formConvert.iva_condition_id"/>
              </b-col>              
            </b-row>            
          </b-tab>                       
          <b-tab no-body title="Listas de Precios">
            <b-row>
              <b-col lg="12" :key="convertPriceForceUpdateKey" class="cost-supplier-products-edit-overflow">
                <b-row v-for="element in arr.listPrices" :key="element.id">
                  <b-col lg="5">
                    <div class="mt-3">
                      <b>Lista:</b> {{element.name}} ({{element.reference}}) <br>
                      <b>Defecto:</b> {{Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits: 2}).format( getUtility(element) )}}
                    </div>
                  </b-col>
                  <b-col lg="7" v-if="crud.formConvert.lists[element.id]">
                    <b-row>
                      <b-col lg="4 text-center">                    
                        <b-form-group label="Agregar" :title="crud.formConvert.lists[element.id]['add'] == true ? 'Se encuentra dentro de la lista de precios':'NO está incluido en la lista de precios'">
                          <b-form-checkbox size="md" v-model="crud.formConvert.lists[element.id]['add']"></b-form-checkbox>                          
                        </b-form-group>
                      </b-col>
                      <b-col lg="4">
                        <b-form-group :label="'Utilidad'">
                          <b-form-input type="number"
                                        size="sm"
                                        v-model="crud.formConvert.lists[element.id]['utility']"
                                        placeholder="Ingresar utilidad diferencial para esta lista">
                          </b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col lg="4">
                        <b-form-group :label="'Descuento'">
                          <b-form-input type="number"
                                        size="sm"
                                        v-model="crud.formConvert.lists[element.id]['desc']"
                                        placeholder="Ingresar el descuento para esta lista">
                          </b-form-input>
                        </b-form-group>
                      </b-col>                  
                    </b-row>
                  </b-col>
                  <b-col lg="12">
                    <hr class="mt-0 mb-2">
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>                    

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formConvert.active=false">Cerrar</b-button>
          <b-button variant="dark" @click="saveProduct()">GUARDAR</b-button>        
        </div>                     
      </b-modal>

      <!-- COMPARE PRICE -->
      <b-modal v-model="modal.formCompare.active"
               header-bg-variant="dark"
               size="xl"
               header-text-variant="white">

        <div slot="modal-header">
          <Minimize />
          {{this.modal.formCompare.title}}
        </div>

        <b-row>
          <b-col lg="12">
            <b>{{this.itemSelectedCompare.toUpperCase()}}</b>
          </b-col>
          <b-col lg="12">
            <b-table class="mb-0" 
                  responsive
                  head-variant="dark"
                  :hover="true"
                  :small="true"
                  :fixed="false"
                  :items="tableCompare.items"
                  :fields="tableCompare.fields"
                  :filter="tableCompare.filter"
                  :current-page="tableCompare.currentPage"
                  :per-page="tableCompare.perPage"
                  :busy="tableCompare.isBusy"                     
                  v-if="tableCompare.items.length || tableCompare.isBusy">   
                
              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"                    
                  :key="field.key"
                  :style="{ width: field.width }"
                >
              </template> 

              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Cargando...</strong>
                </div>
              </template>

              <template v-slot:cell(supplier)="data">
                {{data.item.supplier.name}}
              </template>

              <template v-slot:cell(unit_measurement)="data">   
                <div :title="data.item.unit_measurement_purchase && data.item.unit_measurement_purchase.name">
                  {{ data.item.unit_measurement_purchase.reference }}
                </div>
              </template>

              <template v-slot:cell(price_list)="data">
                <b>
                  {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency }).format(data.item.price_list)}}                      
                </b>
              </template>

              <template v-slot:cell(extra)="data">
                <span v-if="data.item.additional">
                  + {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency }).format(data.item.additional)}}                                          
                </span>                                        
              </template>   

              <template v-slot:cell(percentage_discount)="data">
                <span v-if="getDiscount(data.item,1)"> -{{getDiscount(data.item,1)}} </span>
                <span v-if="getDiscount(data.item,2)"> -{{getDiscount(data.item,2)}} </span>
                <span v-if="getDiscount(data.item,3)"> -{{getDiscount(data.item,3)}} </span>
                
                <span v-if="getDiscount(data.item,1) || getDiscount(data.item,2) || getDiscount(data.item,3)">
                  %
                </span>
              </template>

              <template v-slot:cell(additional)="data">
                <span v-if="getAdditional(data.item,1)"> +{{getAdditional(data.item,1)}} </span>
                <span v-if="getAdditional(data.item,2)"> +{{getAdditional(data.item,2)}} </span>                    
                
                <span v-if="getAdditional(data.item,1) || getAdditional(data.item,2)">
                  %
                </span>
              </template>        

              <template v-slot:cell(extra_1)="data">
                <span v-if="data.item.additional_1">
                  + {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency }).format(data.item.additional_1)}}                                          
                </span>                                        
              </template>   

              <template v-slot:cell(cost)="data">
                <b>
                  {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency }).format( data.item.price_cost )}}                      
                </b>
              </template>    

              <template v-slot:cell(default)="data">
                <b-badge v-if="data.item.default" variant="dark">
                  SI
                </b-badge>
              </template>

              <template v-slot:cell(last_update)="data">
                <span v-if="diffLastUpdate(data.item) <= 7" variant="dark" v-b-tooltip.hover :title="'Última Atualización: ' + diffLastUpdate(data.item) + ' día/s'">
                  <b-icon icon="circle-fill" font-scale="1" variant="success"></b-icon>
                </span>                    
                <span v-if="diffLastUpdate(data.item) > 7 && diffLastUpdate(data.item) <= 15" variant="dark" v-b-tooltip.hover :title="'Última Atualización: ' + diffLastUpdate(data.item) + ' día/s'">
                  <b-icon icon="circle-fill" font-scale="1"  variant="warning" style="color: #c4d126 !important;"></b-icon>
                </span>                
                <span v-if="diffLastUpdate(data.item) > 15 && diffLastUpdate(data.item) <= 30" variant="dark" v-b-tooltip.hover :title="'Última Atualización: ' + diffLastUpdate(data.item) + ' día/s'">
                  <b-icon icon="circle-fill" font-scale="1"  variant="warning"></b-icon>
                </span>
                <span v-if="diffLastUpdate(data.item) > 30" variant="dark" v-b-tooltip.hover :title="'Última Atualización: ' + diffLastUpdate(data.item) + ' día/s'">
                  <b-icon icon="circle-fill" animation="throb" font-scale="1" variant="danger"></b-icon>
                </span>                    
              </template>               
            </b-table>
            <b-alert show variant="info" v-else>
              No se encontró ningún producto
            </b-alert>               
          </b-col>
        </b-row>  

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="closeCompare()">Cerrar</b-button>
        </div>                     
      </b-modal>      

      <!-- MODIFICACION PRECIOS LOTE -->
      <b-modal v-model="modal.formEditPriceLote.active"
               header-bg-variant="dark"
               header-text-variant="white">

        <div slot="modal-header">
          <Minimize />
          {{this.modal.formEditPriceLote.title}}
        </div>

        <b-row>
          <b-col lg="12">
            <b-alert show variant="info">
              Esta modificación de PRECIOS afectará a todos los productos filtrados y que estan agrupados por <b>"{{this.itemSelected}}"</b>.
            </b-alert>
          </b-col>
        </b-row>  
        <b-row>
          <b-col lg="6">
            <b-form-group :label="'Porcentaje Aumento (%)'">
              <b-input-group size="sm" append="%">                            
                <b-form-input type="number"                              
                              step="0.01"
                              v-model="crud.formEditPriceLote.percentage"
                              placeholder="Porcentaje"
                              style="padding-left: 0.5rem;">
                </b-form-input>            
              </b-input-group>
            </b-form-group>
          </b-col>                                        
        </b-row>  

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="closeEditPriceLote()">Cerrar</b-button>
          <b-button variant="dark" @click="saveEditPriceLote()">ACTUALIZAR</b-button>        
        </div>                     
      </b-modal> 

      <!-- MODIFICACION DESCUENTO LOTE -->
      <b-modal v-model="modal.formEditDiscountLote.active"
               header-bg-variant="dark"
               size="lg"
               header-text-variant="white">

        <div slot="modal-header">
          <Minimize />
          {{this.modal.formEditDiscountLote.title}}
        </div>

        <b-row>
          <b-col lg="12">
            <b-alert show variant="info">
              Esta modificación de DESCUENTOS afectará a todos los productos filtrados y que estan agrupados por <b>"{{this.itemSelected}}"</b>.
            </b-alert>
          </b-col>  
        </b-row>  

        <b-row>
          <b-col lg="4">
            <b-form-group :label="'Descuento 1 (%)'">
              <b-input-group size="sm" append="%">                            
                <b-form-input type="number"
                              v-model="crud.formEditDiscountLote.discount1"
                              placeholder="Descuento"
                              style="padding-left: 0.5rem;">
                </b-form-input>            
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group :label="'Descuento 2 (%)'">
              <b-input-group size="sm" append="%">                            
                <b-form-input type="number"
                              v-model="crud.formEditDiscountLote.discount2"
                              placeholder="Descuento"
                              style="padding-left: 0.5rem;">
                </b-form-input>            
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group :label="'Descuento 3 (%)'">
              <b-input-group size="sm" append="%">                            
                <b-form-input type="number"                              
                              v-model="crud.formEditDiscountLote.discount3"
                              placeholder="Descuento"
                              style="padding-left: 0.5rem;">
                </b-form-input>            
              </b-input-group>
            </b-form-group>
          </b-col>        
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="closeEditDiscountLote()">Cerrar</b-button>
          <b-button variant="dark" @click="saveEditDiscountLote()">ACTUALIZAR</b-button>        
        </div>                     
      </b-modal>   
      
      <!-- CRUD PRECIOS -->
      <b-modal  v-model="modal.formPrice.active"
                header-bg-variant="dark"
                header-text-variant="white"
                size="lg">
        <div slot="modal-header">
          {{this.modal.formPrice.title}}
        </div>

        <b-row>         
          <b-col md="12">
            <div class="crud-price-list-suppliers-products-title" v-if="itemSelectedPrice">
              <div class="crud-price-list-suppliers-products-title">
                <span v-if="itemSelectedPrice.code" class="crud-price-list-suppliers-products-code">
                  {{this.itemSelectedPrice.code}} 
                </span><br>
                {{this.itemSelectedPrice.name}}
              </div>
              <hr>
            </div>
          </b-col>
          <b-col md="12">
            <b-table class="mb-0 crud-products-table-prices"
                    responsive
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="tablePrices.items"
                    :fields="tablePrices.fields"                            
                    v-if="tablePrices.items && tablePrices.items.length">   

              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"                    
                  :key="field.key"
                  :style="{ width: field.width }"
                >
              </template> 
              
              <template v-slot:cell(list)="data">
                <div v-if="data.item.list">
                  {{data.item.list.name}}
                  <span v-if="data.item.list.public" class="crud-product-list-prices-public">
                    (Pública)
                  </span>
                </div>
              </template>                                                

              <template v-slot:cell(price_list)="data">       
                <div v-if="data.item.unit_measurement_initial.reference != data.item.unit_measurement_result.reference">
                  {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(sumDiscountPrice(data.item.amount_initial, data.item.desc, data.item))}}                
                  <span v-if="data.item.unit_measurement_initial">{{data.item.unit_measurement_initial.reference}}</span>
                </div>
              </template>

              <template v-slot:cell(price_public)="data">                
                {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(sumDiscountPrice(data.item.amount_total, data.item.desc, data.item))}}                
                <span v-if="data.item.unit_measurement_result">{{data.item.unit_measurement_result.reference}}</span>
              </template>

              <template v-slot:cell(desc)="data">       
                <div v-if="parseFloat(data.item.desc) > 0">
                  {{Intl.NumberFormat('es-AR',{style:'percent', maximumFractionDigits:2}).format(parseFloat(data.item.desc)/100)}}
                </div>
              </template>

              <template v-slot:cell(price_desc)="data">                
                {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(data.item.amount_total)}}                
                <span v-if="data.item.unit_measurement_result">{{data.item.unit_measurement_result.reference}}</span>
              </template>

              <template v-slot:cell(price_iva)="data">                
                {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(data.item.amount_total_iva)}}                
                <span v-if="data.item.unit_measurement_result">{{data.item.unit_measurement_result.reference}}</span>
              </template>              
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron precios para este producto</b-alert>
          </b-col> 
        </b-row>
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formPrice.active=false">Salir</b-button>          
        </div>
      </b-modal>      

      <!-- PRECIOS VENTA POR DEFECTO -->
      <b-modal  v-model="modal.formPriceSaleDefault.active"
                header-bg-variant="dark"
                header-text-variant="white"
                size="sm">
        <div slot="modal-header">
          {{this.modal.formPriceSaleDefault.title}}
        </div>

        <b-row>         
          <b-col md="12">
                <FindObject render="search"
                            type="priceList" 
                            @select-object="loadPriceList($event)" 
                            :valueID="crud.formPriceSaleDefault.price_list_id"                            
                            :where="conditionPriceListActive"/>      
          </b-col>
        </b-row>
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" size="sm" @click="modal.formPriceSaleDefault.active=false">Salir</b-button>   
          <b-button variant="dark" size="sm" @click="savePriceSalesDefault()">GUARDAR</b-button>
        </div>
      </b-modal>       
    </b-col>
  </b-row>
</template>

<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'  
  import Funciones from '@/handler/funciones' 
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'   
  import FindObject from '@/components/inc/find/findObject'
  import Minimize from '@/components/inc/modal/minimize'
  import moment from 'moment'      
  import Param from '@/config/parameters'  
  import Storage from '@/handler/storageLocal'

  import Vue from 'vue'
  import { VueTreeList, Tree, TreeNode } from 'vue-tree-list'
  Vue.use(VueTreeList)  

  export default {
    components: {
      FindObject,
      VueTreeList,
      Minimize,
    },
    data: () => {
      return {      
        access: {
          module_id: Modules.COSTOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudPriceListSuppliers',
          elements: {
            changeProductsSale: true,
          }
        }, 
        parameters: {
          haveLines: Helper.hasParametersAccess(Param.P7),          
          haveMeasurement: Helper.hasParametersAccess(Param.P10),
          enabledPriceSales: Helper.hasParametersAccess(Param.P60),
        },        
        table : {
          items: [],
          fields: [],          
          filter: null,
          mostrarFiltros: false,
          tablePaginate: null,
          currentPage: 1,
          perPage: 0,
          isBusy: false,          
        },   
        tableHistory: {
          items: [],
          fields: [            
            {key: 'date', label: 'Fecha', class:'text-left align-middle', width:"30px"},
            {key: 'unit_measurement', label: 'U.Med.', class:'text-center align-middle text-truncate', width:"20px"},            
            {key: 'price_list', label: 'Lista', class:'text-right align-middle', width:"80px"},            
            {key: 'extra', label: '+ ($)', class:'text-right align-middle', width:"80px"},
            {key: 'percentage_discount', label: '- (%)', class:'text-right align-middle', width:"80px"},
            {key: 'additional', label: '+ (%)', class:'text-right align-middle', width:"80px"},
            {key: 'extra_1', label: '+ ($)', class:'text-right align-middle', width:"80px"},
            {key: 'cost', label: 'Compra', class:'text-right align-middle', width:"100px"},
          ],        
          tablePaginate: true,
          currentPage: 1,
          perPage: 50,
          isBusy: false,          
        },
        tableCompare: {
          items: [],
          fields: [            
            {key: 'supplier', label: 'Proveedor', class:'text-left align-middle text-truncate', width:"120px"},
            {key: 'unit_measurement', label: 'U.Med.', class:'text-center align-middle text-truncate', width:"20px"},            
            {key: 'price_list', label: 'Lista', class:'text-right align-middle', width:"80px"},                        
            {key: 'extra', label: '+ ($)', class:'text-right align-middle', width:"80px"},
            {key: 'percentage_discount', label: '- (%)', class:'text-right align-middle', width:"80px"},
            {key: 'additional', label: '+ (%)', class:'text-right align-middle', width:"80px"},
            {key: 'extra_1', label: '+ ($)', class:'text-right align-middle', width:"80px"},
            {key: 'cost', label: 'Compra', class:'text-right align-middle', width:"80px"},            
            {key: 'last_update', label: '', class:'text-center align-middle', width:"20px"},            
            {key: 'default', label: 'Default', class:'text-center align-middle', width:"20px"},
          ],        
          tablePaginate: true,
          currentPage: 1,
          perPage: 50,
          isBusy: false,          
        },      
        tablePrices : {
          items: [],
          fields: [
            {key: 'list', label: 'Lista', class:"align-middle", width:"25%"},
            {key: 'price_list', label: '', class:"align-middle text-right", width:"15%"},
            {key: 'price_public', label: 'Precio', class:"align-middle text-right", width:"15%"},
            {key: 'desc', label: 'Descuento', class:"align-middle text-right", width:"15%"},
            {key: 'price_desc', label: 'Precio S/IVA', class:"align-middle text-right", width:"15%"},
            {key: 'price_iva', label: 'Precio C/IVA', class:"align-middle text-right", width:"15%"},
          ]
        },                
        crud: {          
          formImport: {
            suppliers: null,
            suppliers_id: 0,
            typeImport: 'default',
            file: '',
            type: ''            
          },
          formConvert: {
            id: 0,
            type: 'NUEVO',
            barcode: '',
            code: '',
            name: '',
            category: '',
            subcategory: '',
            line: '',
            subline: '',            
            products_id: 0,
            products: null,
            changeType: false,
            lists: [],
            price_list: 0,
            percentage_discount: 0,
            percentage_discount_1: 0,
            percentage_discount_2: 0,
            additional: 0,
            additional_1: 0,
            additional_percentage: 0,
            additional_percentage_1: 0,
            unit_measurement_purchase_id: 0,
            unit_measurement_purchase: null,
            unit_measurement_sale_id: 0,
            unit_measurement_sale: null,            
            unit_measurement_sale_conversion: 0,   
            suppliers_id: 0,       
            depth: 0,
            long: 0,
            height: 0,
            weight: 0,
            iva_condition: null,
            iva_condition_id: 0,
            reference_external: null,
          },
          formEditPriceLote: {
            percentage: 0,
            operations: '+',
            filterQuery: []
          },
          formEditDiscountLote: {
            discount1: 0,
            discount2: 0,
            discount3: 0,
            filterQuery: []
          },          
          formPriceSaleDefault: {
            price_list: null,
            price_list_id: 0,
          },
          prodTMP: [],
        },                      
        arr: {
          fiscalCondition: [],
          listProducts: [],
          listPrices: [],
          export: [],
          typeConvert: [
            { item: 'NUEVO', name: 'Nuevo Producto' },
            { item: 'EDITAR', name: 'Editar Producto' },
          ],          
          filters : {
            suppliers: [],            
          }, 
          select: {
            haveReferenceExternal: [
              {code:true, label: 'Con Referencia'},
              {code:false, label: 'Sin Referencia'},              
            ],
            linkedProduct:[
              {code:true, label: 'Vinculados'},
              {code:false, label: 'Sin Vincular'},              
            ],               
            lastUpdate:[
              {value: null, text: 'Rango de Actualización'},
              {
                label: "SEMAFORO",
                options: [
                  {value: '0-7', text: 'Últimos 7 días'},
                  {value: '7-15', text: 'Entre 7 y 15 días'},
                  {value: '15-30', text: 'Entre 15 y 30 días'},
                  {value: '30-9999', text: 'Mas de 30 días'},
                ]
              },{
                label: "OTRAS OPCIONES",
                options: [
                  {value: 'hoy', text: 'Modificaciones de HOY'},              
                  {value: 'hoy-no', text: 'HOY sin modificación'},                    
                ]
              }            
            ],  
            notImported:[
              {code:true, label: 'Importados'},
              {code:false, label: 'No Importados'},              
            ],                           
            typeImport:[
              {value:'default', text: 'Actualiza solo los productos importados'},
              {value:'warning', text: 'Marca los productos que no se importaron en la lista'},
              {value:'delete', text: 'Elimina los productos que no se importaron en la lista'},              
            ],                                       
          }                               
        },           
        filters: {
          suppliers: null,
        },  
        filterQuery: {
          suppliers_id: 0,
          category: '',
          subcategory: '',
          text: '',
          haveReferenceExternal: null,
          linkedProduct: null,
          lastUpdate: null,
          notImported: null,
          paginate: true,
          showPriceSale: false,
        }, 
        modal: {
          formImport: {
            active: false,
            title: ''
          },
          formHistory: {
            active: false,
            title: ''            
          },
          formConvert: {
            active: false,
            title: ''                        
          },
          formCompare: {
            active: false,
            title: ''
          },    
          formEditPriceLote: {
            active: false,
            title: ''
          },
          formEditDiscountLote: {
            active: false,
            title: ''            
          },
          formPrice: {
            active: false,
            title: ''
          },
          formPriceSaleDefault: {
            active: false,
            title: ''            
          }
        }, 
        convertPriceForceUpdateKey: 0,
        itemSelected: '',
        itemSelectedHistory: '',
        itemSelectedConvert: '',
        itemSelectedCompare: '',
        suppliers: null,
        suppliersTree: new Tree([]),       
        statusEditPrice: false, 
        itemSelectedPrice: null, 
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      this.access.elements.changeProductsSale = Helper.hasAccessElement(this.access, 'change_products_sale')       
      /* Fin configuracion */
    },     
    mounted() {         
      this.restoreFilterStorage()
      this.loadFieldTable()      

      this.loadSuppliers()         
      this.filterLoad()      
      this.loadListsPrices()
      this.loadFiscalCondition()      
    },     
    computed: {
      getCurrency() {
        return 'ARS'
      },
      getCurrencySymbol() {
        return '$'
      },

      // CONDITIONS SELECT
      conditionSuppliersActive(){
        return [
          {field: 'active', condition: true},
          {field: 'shipping', condition: false}
        ];
      },     
      conditionPriceListActive(){
        return [{field: 'active', condition: true}];
      },    
      
      // PRICE SALES DEFAULT
      showPriceSalesDefault() {        
        let priceID = 0
        if(this.hasShowPriceSales) {
          if(this.crud.formPriceSaleDefault.price_list_id) {
            priceID = this.crud.formPriceSaleDefault.price_list_id
          }
        }
        return priceID
      },
      
      // PARAMETROS
      hasLines() {
        return this.parameters.haveLines
      },
      hasMeasurement() {        
        return this.parameters.haveMeasurement
      },      
      isProcessSales() {
        var status = false
        var modulesActive = Session.getSession().auth.user.permissions.modules
        modulesActive.forEach(element => {
          if(element.id == Modules.VENTAS || element.id == Modules.PRESUPUESTOS) {
            status = true
          }
        })
        return status
      }, 
      hasShowPriceSales() {
        return this.parameters.enabledPriceSales
      },
    },      
    methods: {
      // CONFIGURACION
      loadFieldTable () {                
        this.table.fields = []
        
        if(this.showPriceSalesDefault) {
          this.table.fields.push({key: 'code', label: 'Código', class:'text-left align-middle text-truncate', width:"35px"});
          this.table.fields.push({key: 'name', label: 'Nombre', class:'text-left align-middle text-truncate', width:"130px"});             
          this.table.fields.push({key: 'raise', label: '', class:'text-right align-middle text-truncate', width:"20px"});           
          this.table.fields.push({key: 'unit_measurement', label: 'U.Med.', class:'text-center align-middle text-truncate', width:"20px"});          
          this.table.fields.push({key: 'price_list', label: 'P.Lista', class:'text-right align-middle', width:"100px"});
          this.table.fields.push({key: 'cost', label: 'P.Compra', class:'text-right align-middle', width:"100px"});
          this.table.fields.push({key: 'prices', label: 'P.Venta', class:'text-right align-middle', width:"100px"});
          this.table.fields.push({key: 'prices_iva', label: 'P.Venta + IVA', class:'text-right align-middle', width:"100px"});    
          this.table.fields.push({key: 'last_update', label: '', class:'text-center align-middle', width:"20px"});
          this.table.fields.push({key: 'default', label: 'Default', class:'text-center align-middle', width:"20px"});
          this.table.fields.push({key: 'f_action', label:'', class:'text-right align-middle', width:"10px"});                
        } else {
          this.table.fields.push({key: 'code', label: 'Código', class:'text-left align-middle text-truncate', width:"35px"});
          this.table.fields.push({key: 'name', label: 'Nombre', class:'text-left align-middle text-truncate', width:"130px"});                       
          this.table.fields.push({key: 'raise', label: '', class:'text-right align-middle text-truncate', width:"20px"});
          this.table.fields.push({key: 'unit_measurement', label: 'U.Med.', class:'text-center align-middle text-truncate', width:"20px"});
          this.table.fields.push({key: 'price_list', label: 'P.Lista', class:'text-right align-middle', width:"100px"});           
          this.table.fields.push({key: 'cost', label: 'P.Compra', class:'text-right align-middle', width:"100px"});
          this.table.fields.push({key: 'last_update', label: '', class:'text-center align-middle', width:"20px"});
          this.table.fields.push({key: 'default', label: 'Default', class:'text-center align-middle', width:"20px"});
          this.table.fields.push({key: 'f_action', label:'', class:'text-right align-middle', width:"10px"});          
        }                        
      },

      // IMPORT BY CSV
      openImportCSV() {
        this.crud.formImport.type = 'import-costs-suppliers-products'
        this.crud.formImport.file = ''        
        this.crud.formImport.typeImport = 'default'
        this.crud.formImport.suppliers_id= (this.filterQuery['suppliers_id'] != undefined) ? this.filterQuery['suppliers_id'] : 0
        this.crud.formImport.suppliers= null
            
        this.modal.formImport.title = "Gestor de Importaciones"
        this.modal.formImport.active = true
      },
      importCSV() {     
        var message = '¿Continuar con la importación?'
        if(this.crud.formImport.typeImport == 'warning') {
          message = 'El proceso marcará aquellos productos que no se encuentran en el archivo, ¿Continuar con la importación?'
        } 
        if(this.crud.formImport.typeImport == 'delete') {
          message = 'El proceso ELIMINARÁ aquellos productos que no se encuentran en el archivo, ¿Continuar con la importación?'
        }
        

        this.$bvModal.msgBoxConfirm(message, {
          title: 'Importar desde CSV',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'dark',
          okTitle: 'IMPORTAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {          
          if (value) {            
            let loader = this.$loading.show();

            var file = new FormData();
            file.append("file", this.crud.formImport.file);            
              
            var result = serviceAPI.importarSuppliersProductosPrices(JSON.stringify(this.crud.formImport), file);

            result.then((response) => {              
              this.modal.formImport.active = false              
              loader.hide()
              this.filterLoad()
              this.loadSuppliers()
              this.selectedItem(this.filterQuery, true)
              this.$awn.success("Archivo importado con éxito");
            })
            .catch(error => {               
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {             
          this.$awn.alert(Error.showError(error));
        })        
      },
      goDownloadFormat(value) {
        var win = window.open(Helper.getPathPublic(value), '_blank');        
        win.focus();
      },
      loadSuppliersImportCSV(object) {
        if(object){
          this.crud.formImport.suppliers = object
          this.crud.formImport.suppliers_id = object.id
        } else {
          this.crud.formImport.suppliers = null
          this.crud.formImport.suppliers_id = 0
        }
      },
      changeTypeImportCSV() {
        if(this.crud.formImport.typeImport == 'warning'){          
          this.$bvModal.msgBoxOk('Se marcarán todos los productos que no se agregaron al archivo. Se verificará en toda la lista del proveedor seleccionado', {
            title: 'ATENCIÓN!!! Importación de Productos por Proveedor',
            size: 'md',
            buttonSize: 'sm',            
            okVariant: 'warning',            
            headerBgVariant: 'warning',
            bodyBgVariant: 'light',
            footerBgVariant: 'light',            
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
        } 
        
        if(this.crud.formImport.typeImport == 'delete'){          
          this.$bvModal.msgBoxOk('Se eliminarán todos los productos que no se agregaron al archivo. Se verificará en toda la lista del proveedor seleccionado', {
            title: 'ATENCIÓN!!! Importación de Productos por Proveedor',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            headerBgVariant: 'danger',
            bodyBgVariant: 'light',
            footerBgVariant: 'light',            
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })          
        }
      },

      // FILTER
      filterLoad() {   
        this.filterLoadSuppliers()          
      },
      filterLoadSuppliers() {
        var result = serviceAPI.obtenerProveedoresOnlyImport()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.suppliers = []
          data.forEach(element => {   
            if(element.active && !element.shipping) {
              this.arr.filters.suppliers.push({ code: element.id, label: element.name })            
            }
          });          
          
          this.loadFilterByWidget()        
        })   
      },      

      // TREE
      loadSuppliers() {
        let loader = this.$loading.show()   
   
        var result = serviceAPI.obtenerProveedoresForTree(this.filters)
        result.then((response) => {
          loader.hide()
          
          this.arr.listProducts = [];
          this.suppliers = [];  
          this.suppliersTree = new Tree(this.suppliers);  

          var data = response.data                 
          if(Object.entries(data).length) {
            var arrSuppliers = []
            Object.entries(data).forEach(([key, element]) => {            
              arrSuppliers.push({
                id: element.id,
                name: element.name,
                category: element.category,
                subcategory: element.subcategory,
              })
            });
                          
            arrSuppliers = Funciones.sortArrayInteger(arrSuppliers,'id','asc')              
            
            var groupCat = []            
            arrSuppliers.forEach(element => {
              if(groupCat[element.id] == undefined) {
                groupCat[element.id] = []                 
                groupCat[element.id].id = element.id    
                groupCat[element.id].name = element.name    
                groupCat[element.id].children = []            
              }
            });            
            groupCat = groupCat.filter(Boolean)

            this.configTree(groupCat, arrSuppliers)
          }           
        })
        .catch(error => {         
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });         
      },      
      configTree(group, data) {               
        this.suppliers = []             

        group.forEach((element, index) => {                                
          this.suppliers.push({            
            id: element.id,                  
            name: element.name,            
            dragDisabled: true,
            addTreeNodeDisabled: true,
            addLeafNodeDisabled: true,
            editNodeDisabled: true,
            delNodeDisabled: true,                     
            children: this.getChildrenCat(element.id, data),
          })       
        })                  

        this.suppliersTree = new Tree(this.suppliers)                
      },
      getChildrenCat(supplier_id, data) {                
        var arrChildrenCat = []
        if(data) {        
          var catSupplier = []
          data.forEach((element, index) => {   
            if(element.id == supplier_id) {
              catSupplier.push(element)
            }
          });
          var arrCat = Funciones.groupBy(catSupplier, (item)=>{return item.category})          

          arrCat.forEach((element, index) => {                         
            arrChildrenCat.push({                   
              id: element[0].category,      
              name: element[0].category,                
              dragDisabled: true,
              addTreeNodeDisabled: true,
              addLeafNodeDisabled: true,
              editNodeDisabled: true,
              delNodeDisabled: true,                  
              isLeaf: false,      
              children: this.getChildrenSubcat(element),                      
            })            
          });     
        }         
        return arrChildrenCat
      },
      getChildrenSubcat(data) {                                       
        var arrChildrenSubcat = []
        if(data) {        
          data.forEach((element, index) => {                             
            arrChildrenSubcat.push({    
              id: element.subcategory,      
              name: element.subcategory,                
              dragDisabled: true,
              addTreeNodeDisabled: true,
              addLeafNodeDisabled: true,
              editNodeDisabled: true,
              delNodeDisabled: true,                  
              isLeaf: true,                            
            })                
          });     
        } 
        return arrChildrenSubcat  
      },      
      selectedItem(params, forceSelected=false, forceBusy=true, msjDefault = '') {              
        this.table.isBusy = forceBusy

        if(!forceSelected) {
          let filterQuery = null
          if(params.parent.id) {
            if(params.isLeaf) {              
              filterQuery = {
                suppliers_id: params.parent.parent.id,
                category: params.parent.name,
                subcategory: params.name
              }              
              this.itemSelected = params.parent.parent.name.toUpperCase() + ' > ' + params.parent.name.toUpperCase() + ' > ' + params.name.toUpperCase()

            } else {              
              filterQuery = {
                suppliers_id: params.parent.id,
                category: params.name,
                subcategory: '',
              }              
              this.itemSelected = params.parent.name.toUpperCase() + ' > ' + params.name.toUpperCase()
            }
            
          } else {
            filterQuery = {
              suppliers_id: params.id,          
                category: '',
                subcategory: '',              
            }

            this.itemSelected = params.name.toUpperCase()
          }   

          this.filterQuery = Object.assign({}, this.filterQuery, filterQuery);          
        }
                
        this.filterQuery.showPriceSale = this.showPriceSalesDefault
        this.filterQuery.paginate = true

        var result = serviceAPI.obtenerProductsByFilter(this.filterQuery, this.table.currentPage)        
        result.then((response) => {          
          var data = response.data       
          this.table.tablePaginate = data      
          this.table.items = data.data

          if(this.table.items) {
            this.table.items.forEach((element, index) => {
              if(!this.crud.prodTMP[index])
                this.crud.prodTMP[index] = []
              
              this.crud.prodTMP[index] = {
                id: element.id,            
                product_id: element.products_id,                                
                price_list: element.price_list ? element.price_list : 0,
              }                           

              element._cellVariants = {
                ...element._cellVariants, 
                price_list: 'secondary',                
              };
              if(element.price_list != element.price_cost) {
                element._cellVariants = {
                  ...element._cellVariants, 
                  price_list: 'secondary',
                  cost: 'secondary',                
                };
              }

              if(this.showPriceSalesDefault) {
                element.product.prices.forEach(_element => {                
                  if(_element && (_element.list.id == this.showPriceSalesDefault)) {
                    element._cellVariants = { 
                      ...element._cellVariants, 
                      prices: 'primary',
                      prices_iva: 'primary'
                    }                  
                  }                
                });                           
              }
                           
              if(moment(element.last_update_price).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) {                
                element._cellVariants = { 
                  ...element._cellVariants, 
                  code: 'success',
                  name: 'success',
                  unit_measurement: 'success',
                  raise: 'success',
                }                                  
              }
            })
          }
          
          this.table.isBusy = false

          if(msjDefault)
            this.$awn.info(msjDefault)          
        })
      },    
      prepareExport() {                
        this.$bvModal.msgBoxConfirm('¿Descargar lista de precios de los productos del proveedor seleccionado?', {
          title: 'Descargar lista de precios',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'dark',
          okTitle: 'GENERAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {          
          if (value) {          
            this.$awn.info("Estamos procesando el archivo, por favor espere...");
            let loader = this.$loading.show();

            this.arr.export = []        
            
            this.filterQuery.paginate = false
            var result = serviceAPI.obtenerProductsByFilter(this.filterQuery, null)        
            result.then((response) => {              
              this.arr.listProducts = response.data
                                
              if(this.arr.listProducts.length) {                
                this.arr.listProducts.forEach(element => { 

                  var arrExport = []
                  arrExport['code'] = element.code
                  arrExport['name'] = element.name
                  arrExport['category'] = element.category
                  arrExport['subcategory'] = element.subcategory
                  arrExport['line'] = element.line
                  arrExport['subline'] = element.subline
                  arrExport['depth'] = parseFloat(element.depth).toFixed(2).replace('.',',')
                  arrExport['long'] = parseFloat(element.long).toFixed(2).replace('.',',')
                  arrExport['height'] = parseFloat(element.height).toFixed(2).replace('.',',')
                  arrExport['weight'] = parseFloat(element.weight).toFixed(2).replace('.',',')
                  arrExport['unit_measurement_purchase'] = element.unit_measurement_purchase.reference
                  arrExport['unit_measurement_sale'] = ''
                  arrExport['unit_measurement_sale_conversion'] = ''
                  arrExport['iva_condition'] = parseInt(element.iva_condition)
                  arrExport['code_system'] = ''
                  arrExport['barcode'] = ''
                  arrExport['reference_external'] = element.reference_external
                  arrExport['default'] = element.default
                  arrExport['price_list'] = parseFloat(element.price_list).toFixed(2).replace('.',',')
                  arrExport['additional'] = parseFloat(element.additional).toFixed(2).replace('.',',')
                  arrExport['percentage_discount'] = parseFloat(element.percentage_discount).toFixed(2).replace('.',',')
                  arrExport['percentage_discount_1'] = parseFloat(element.percentage_discount_1).toFixed(2).replace('.',',')
                  arrExport['percentage_discount_2'] = parseFloat(element.percentage_discount_2).toFixed(2).replace('.',',')
                  arrExport['additional_percentage'] = parseFloat(element.additional_percentage).toFixed(2).replace('.',',')
                  arrExport['additional_percentage_1'] = parseFloat(element.additional_percentage_1).toFixed(2).replace('.',',')
                  arrExport['additional_1'] = parseFloat(element.additional_1).toFixed(2).replace('.',',')
                  
                  if(element.product) {
                    arrExport['code_system'] = element.product.code
                    arrExport['barcode'] = element.product.barcode
                    
                    if(element.product.unit_measurement_sale) {                
                      arrExport['unit_measurement_sale'] = element.product.unit_measurement_sale.reference
                      arrExport['unit_measurement_sale_conversion'] = parseInt(element.product.unit_measurement_sale_conversion)
                    }

                    if(element.product.prices.length > 0) {     
                      element.product.prices.forEach(element1 => {
                        arrExport['utility_' + element1.list.reference] = parseFloat(element1.utility).toFixed(2).replace('.',',')
                        arrExport['desc_' + element1.list.reference] = parseFloat(element1.desc).toFixed(2).replace('.',',')
                      });
                    } else {
                      this.arr.listPrices.forEach(element1 => {
                        arrExport['utility_' + element1.reference] = 0
                        arrExport['desc_' + element1.reference] = 0
                      });
                    }
                  } 
                                
                  this.arr.export.push(arrExport)
                });                 
                                
                loader.hide()
                this.$awn.success("Finalizamos el proceso, descargando archivo...");

                Funciones.exportToCSV(this.arr.export, 'list-cost-products')
              }
            })
            .catch(error => {                 
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })          
          }
        })
        .catch(error => {                                     
          this.$awn.alert(Error.showError(error));
        })              
      },

      // TABLA
      getRowCount (items) {
        return items.length
      },      
      getDiscount(item, nivel=1) {
        if(nivel==1) {
          return parseFloat(item.percentage_discount)
        }        
        if(nivel==2) {
          return parseFloat(item.percentage_discount_1)
        }        
        if(nivel==3) {
          return parseFloat(item.percentage_discount_2)
        }                        
      },
      getAdditional(item, nivel=1) {
        if(nivel==1) {
          return parseFloat(item.additional_percentage)
        }        
        if(nivel==2) {
          return parseFloat(item.additional_percentage_1)
        }                
      },
      diffLastUpdate(item) {
        if(item.last_update_price) {
          var dateNow = moment();
          var dateLast = moment(item.last_update_price);    
          return dateNow.diff(dateLast, 'days') + 1 
        } else {
          return 1000 
        }        
      },

      // HISTORICO PRECIOS
      showHistory(item) {              
        this.tableHistory.isBusy = true
        this.itemSelectedHistory = this.itemSelected + ' - (' + item.code + ') ' + item.name
        this.tableHistory.items = item.history

        this.modal.formHistory.title = "Historial de Precios"
        this.modal.formHistory.active = true
        this.tableHistory.isBusy = false
      },
      closeHistory() {
        this.modal.formHistory.active = false
      },     

      // CONFIGURACION PRODUCTOS
      loadListsPrices() {
        var result = serviceAPI.obtenerLista()        
        result.then((response) => {
          var data = response.data    
          data.forEach(element => {
            if(element.active) {
              this.arr.listPrices.push(element)
            }
          });
        })  
      },
      getUtility(item) {
        return item.utility / 100 
      },
      addProduct() {        
        this.itemSelectedConvert = this.itemSelected
        this.crud.formConvert.type = 'NUEVO'
        this.crud.formConvert.id = 0
        this.crud.formConvert.price_list = 0
        this.crud.formConvert.percentage_discount = 0
        this.crud.formConvert.percentage_discount_1 = 0
        this.crud.formConvert.percentage_discount_2 = 0
        this.crud.formConvert.additional_percentage = 0
        this.crud.formConvert.additional_percentage_1 = 0
        this.crud.formConvert.additional = 0        
        this.crud.formConvert.additional_1 = 0
        this.crud.formConvert.unit_measurement_purchase = null
        this.crud.formConvert.unit_measurement_purchase_id = 18
        this.crud.formConvert.suppliers_id = (this.filterQuery['suppliers_id'] != undefined) ? this.filterQuery['suppliers_id'] : 0
        
        this.crud.formConvert.products_id = 0
        this.crud.formConvert.products = null
        this.crud.formConvert.changeType = false        
        this.crud.formConvert.unit_measurement_sale = null
        this.crud.formConvert.unit_measurement_sale_id = 18
        this.crud.formConvert.unit_measurement_sale_conversion = 1

        this.crud.formConvert.barcode = ''
        this.crud.formConvert.code = ''
        this.crud.formConvert.name = ''
        this.crud.formConvert.category = (this.filterQuery['category'] != undefined) ? this.filterQuery['category'] : ''
        this.crud.formConvert.subcategory = (this.filterQuery['subcategory'] != undefined) ? this.filterQuery['subcategory'] : ''
        this.crud.formConvert.line = ''
        this.crud.formConvert.subline = ''
        this.crud.formConvert.depth = 0,
        this.crud.formConvert.long = 0,
        this.crud.formConvert.height = 0,
        this.crud.formConvert.weight = 0,
        this.crud.formConvert.iva_condition = null
        this.crud.formConvert.iva_condition_id = 5      
        this.crud.formConvert.reference_external = null

        this.arr.listPrices.forEach(element => {
          this.crud.formConvert.lists[element.id] = []
          this.crud.formConvert.lists[element.id]['add'] = true
          this.crud.formConvert.lists[element.id]['price_list_id'] = element.id
          this.crud.formConvert.lists[element.id]['utility'] = 0
          this.crud.formConvert.lists[element.id]['desc'] = 0
        })
        
        this.modal.formConvert.title = "Nuevo Producto"
        this.modal.formConvert.active = true  
      },
      editProduct(item) {       
        this.itemSelectedConvert = this.itemSelected
        this.crud.formConvert.id = item.id

        this.crud.formConvert.type = 'EDITAR'
        this.crud.formConvert.price_list = item.price_list
        this.crud.formConvert.percentage_discount = item.percentage_discount 
        this.crud.formConvert.percentage_discount_1 = item.percentage_discount_1 
        this.crud.formConvert.percentage_discount_2 = item.percentage_discount_2 
        this.crud.formConvert.additional_percentage = item.additional_percentage
        this.crud.formConvert.additional_percentage_1 = item.additional_percentage_1
        this.crud.formConvert.additional = item.additional        
        this.crud.formConvert.additional_1 = item.additional_1        
        this.crud.formConvert.unit_measurement_purchase = item.unit_measurement_purchase
        this.crud.formConvert.unit_measurement_purchase_id = item.unit_measurement_purchase_id
        this.crud.formConvert.suppliers_id = item.suppliers_id
        
        this.crud.formConvert.products_id = item.products_id
        this.crud.formConvert.products = item.product
        this.crud.formConvert.changeType = false        
        this.crud.formConvert.unit_measurement_sale = item.product.unit_measurement_sale
        this.crud.formConvert.unit_measurement_sale_id = item.product.unit_measurement_sale_id
        this.crud.formConvert.unit_measurement_sale_conversion = item.product.unit_measurement_sale_conversion          

        this.crud.formConvert.barcode = item.barcode
        this.crud.formConvert.code = item.code
        this.crud.formConvert.name = item.name
        this.crud.formConvert.category = item.category
        this.crud.formConvert.subcategory = item.subcategory  
        this.crud.formConvert.line = item.line
        this.crud.formConvert.subline = item.subline
        this.crud.formConvert.depth = item.depth
        this.crud.formConvert.long = item.long
        this.crud.formConvert.height = item.height
        this.crud.formConvert.weight = item.weight
        this.crud.formConvert.iva_condition = null //no viene cargado, pero lo usa el combo
        this.crud.formConvert.iva_condition_id = item.iva_condition
        this.crud.formConvert.reference_external = item.reference_external

        this.arr.listPrices.forEach(element => {
          this.crud.formConvert.lists[element.id] = []
          this.crud.formConvert.lists[element.id]['add'] = true
          this.crud.formConvert.lists[element.id]['price_list_id'] = 0
          this.crud.formConvert.lists[element.id]['utility'] = 0
          this.crud.formConvert.lists[element.id]['desc'] = 0
        })

        this.arr.listPrices.forEach(element => {
          if(this.crud.formConvert.products_id) {
            var result1 = serviceAPI.mostrarProductosByLista({
              price_list_id: element.id,
              products_id: this.crud.formConvert.products_id,
            })        
            result1.then((response1) => {
              var data1 = response1.data

              this.crud.formConvert.lists[element.id]['add'] = true
              this.crud.formConvert.lists[element.id]['price_list_id'] = parseFloat(data1.products.price_list_id)
              this.crud.formConvert.lists[element.id]['utility'] = parseFloat(data1.products.utility)
              this.crud.formConvert.lists[element.id]['desc'] = parseFloat(data1.products.desc)
              this.convertPriceForceUpdate()
            }).catch(() => {    
              this.crud.formConvert.lists[element.id]['add'] = false
              this.crud.formConvert.lists[element.id]['price_list_id'] = parseFloat(element.id)
              this.crud.formConvert.lists[element.id]['utility'] = 0
              this.crud.formConvert.lists[element.id]['desc'] = 0
              this.convertPriceForceUpdate()
            })                                                   
          }
        });

        this.modal.formConvert.title = "Editar Producto"
        this.modal.formConvert.active = true        
      },
      changeTypeConvert() {
        if(this.crud.formConvert.type == 'NUEVO') {
          this.crud.formConvert.products_id = 0
          this.crud.formConvert.products = null
        }
        if(this.crud.formConvert.type == 'EDITAR') {
          this.crud.formConvert.code = ''
        }        
      },
      loadProductsConvert(object) {
        if(object){
          this.crud.formConvert.products = object
          this.crud.formConvert.products_id = object.id
        } else {
          this.crud.formConvert.products = null
          this.crud.formConvert.products_id = 0
        }
      },
      loadUnitMeasurementPurchase(object) {
        if(object){
          this.crud.formConvert.unit_measurement_purchase = object
          this.crud.formConvert.unit_measurement_purchase_id = object.id  
        } else {
          this.crud.formConvert.unit_measurement_purchase = null
          this.crud.formConvert.unit_measurement_purchase_id = 0
        }
      },
      loadUnitMeasurementSale(object) {
        if(object){
          this.crud.formConvert.unit_measurement_sale = object
          this.crud.formConvert.unit_measurement_sale_id = object.id
        } else {
          this.crud.formConvert.unit_measurement_sale = null
          this.crud.formConvert.unit_measurement_sale_id = 0
        }
      }, 
      loadIvaConditions (object) {
        if(object){
          this.crud.formConvert.iva_condition = object
          this.crud.formConvert.iva_condition_id = object.id             
        } else {
          this.crud.formConvert.iva_condition = null
          this.crud.formConvert.iva_condition_id = 0
        }
      },                 
      saveProduct() {  
        var modalTitle = ''      
        var modalContent = ''

        if(this.crud.formConvert.type=='EDITAR') {
          if(!this.crud.formConvert.products_id) {
            this.$awn.alert("No se cargó la relación con el producto");
            return false
          }

          modalTitle = '¿Continuar con la edición del producto?'
          modalContent = 'Editar Producto'
        }

        if(this.crud.formConvert.type=='NUEVO') {
          if(!this.crud.formConvert.code) {            
            this.$awn.alert("No se cargo el código");
            return false            
          }

          modalTitle = '¿Agregar el nuevo producto?'
          modalContent = 'Agregar Producto'          
        }        
        
        this.$bvModal.msgBoxConfirm(modalContent, {
          title: modalTitle,
          size: 'md',
          buttonSize: 'md',
          okVariant: 'dark',
          okTitle: 'GUARDAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {          
          if (value) {            
            let loader = this.$loading.show();
            
            // preparar datos
            var lists = this.crud.formConvert.lists
            this.crud.formConvert.lists = []
            
            lists.forEach(element => {
              this.crud.formConvert.lists.push({
                add: element.add,
                price_list_id: element.price_list_id, 
                utility: element.utility, 
                desc: element.desc
              })
            });
            // fin preparar datos
            
            var result = serviceAPI.saveProduct(this.crud.formConvert);

            result.then((response) => {              
              this.modal.formConvert.active = false              
              loader.hide()
              this.filterLoad()
              this.loadSuppliers()
              this.selectedItem(this.filterQuery, true)
              this.$awn.success("Registro configurado con éxito");
            })
            .catch(error => {                     
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {    
          this.$awn.alert(Error.showError(error));
        })            
      },
      convertPriceForceUpdate() {
        this.convertPriceForceUpdateKey = this.convertPriceForceUpdateKey + 1
      },
      loadSuppliersAddProduct(object) {
        if(object){
          this.crud.formConvert.suppliers = object
          this.crud.formConvert.suppliers_id = object.id
        } else {
          this.crud.formImport.suppliers = null
          this.crud.formImport.suppliers_id = 0
        }
      },      

      // REFERENCIA EXTERNAL DEDSE BASE DE DATOS
      loadReferenceExternal(object) {                
        if(object){
          this.crud.formConvert.reference_external = object.reference          
        } else {
          if(!this.crud.formConvert.reference_external) {
            this.crud.formConvert.reference_external = null
          }          
        }
      },   

      // MARCAR POR DEFAULT
      setDefault(item) {        
        this.$bvModal.msgBoxConfirm('¿Marcar producto de proveedor por Default para obtener el precio de venta?', {
          title: 'Marcar Por Default',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'dark',
          okTitle: 'MARCAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {          
          if (value) {            
            let loader = this.$loading.show();
            
            var result = serviceAPI.marcarDefault({
              id: item.id
            });

            result.then((response) => {                            
              loader.hide()
              this.filterLoad()
              this.loadSuppliers()
              this.selectedItem(this.filterQuery, true)
              this.$awn.success("Registro marcado con éxito");
            })
            .catch(error => {                     
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {    
          this.$awn.alert(Error.showError(error));
        })    
      },

      // COMPARAR PRECIOS
      showCompare(item) {              
        this.tableCompare.isBusy = true
        this.itemSelectedCompare = '(' + item.product.code + ') ' + item.product.name
                
        var result = serviceAPI.compareProductos({
          products_id: item.products_id,
        })        
        result.then((response) => {
          var data = response.data
          this.tableCompare.items = data        
        })                  

        this.modal.formCompare.title = "Comparar precios con otros proveedores"
        this.modal.formCompare.active = true
        this.tableCompare.isBusy = false
      },
      closeCompare() {
        this.modal.formCompare.active = false
      },
      formatNumber(valor) {
        return valor.toLocaleString()
      },

      // CAMBIO DE PRECIOS
      initChangePrice() {
        this.statusEditPrice = true;
        this.$awn.warning("Iniciando edición de precios");        
      },
      endChangePrice() {
        this.statusEditPrice = false;
        this.$awn.warning("Finalizando edición de precios");
      },     
      savePrice(item) {
        this.$bvModal.msgBoxConfirm('¿Actualizar precio de lista?', {
          title: 'Actualizar Precio',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'ACTUALIZAR',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            var result = serviceAPI.updateProducto({
              id: item.id,
              product_id: item.product_id,
              price_list: item.price_list,
            })
                          
            result.then((response) => {                                                                                       
              this.$awn.warning("Actualizando precio de lista");

              //this.filterLoad()
              //this.loadSuppliers()
              this.selectedItem(this.filterQuery, true, false, 'Precio de lista actualizado')              
            })
            .catch(error => {                              
              //this.filterLoad()
              //this.loadSuppliers()
              this.selectedItem(this.filterQuery, true)                         
              this.$awn.alert(Error.showError(error));
            })  
          }
        })    
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })  
      },
      
      // ELIMINAR PRODUCTO
      deleteProduct(item) {
        this.$bvModal.msgBoxConfirm('¿Eliminar el producto ['+item.code + '] - ' + item.name + '?', {
          title: 'Eliminar producto',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'ELIMINAR',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            var result = serviceAPI.deleteProducto({
              id: item.id,              
            })
                          
            result.then((response) => {                                  
              this.filterLoad()
              this.loadSuppliers()
              this.selectedItem(this.filterQuery, true)              

              this.$awn.success("Producto eliminado");
            })
            .catch(error => {                              
              this.filterLoad()
              this.loadSuppliers()
              this.selectedItem(this.filterQuery, true)                         
              this.$awn.alert(Error.showError(error));
            })  
          }
        })    
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })  
      },

      // EDITAR LOTE
      showEditPriceLote() {
        this.modal.formEditPriceLote.title = "Edición de precios por lotes"
        this.modal.formEditPriceLote.active = true

        this.crud.formEditPriceLote.percentage = 0
        this.crud.formEditPriceLote.operations = '+'
        this.crud.formEditPriceLote.filterQuery = []        
      },
      closeEditPriceLote() {
        this.modal.formEditPriceLote.active = false
      },      
      saveEditPriceLote() {                       
        if(parseFloat(this.crud.formEditPriceLote.percentage) <= 0) {
          this.$awn.alert("El porcentaje debe ser mayor a cero");
          return false
        }
        
        this.$bvModal.msgBoxConfirm('¿Actualizar todos los precio un ' + this.crud.formEditPriceLote.percentage + '%?', {
          title: 'Actualización Precios',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'ACTUALIZAR',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {          
          if (value) {  
            let loader = this.$loading.show();        
                    
            this.crud.formEditPriceLote.filterQuery = this.filterQuery
            this.crud.formEditPriceLote.filterQuery.paginate = false

            var result = serviceAPI.updateProductoLote(this.crud.formEditPriceLote)
                          
            result.then((response) => {                                                                                                                
              this.modal.formEditPriceLote.active = false   

              this.crud.formEditPriceLote.percentage = 0
              this.crud.formEditPriceLote.operations = '+'
              this.crud.formEditPriceLote.filterQuery = []

              loader.hide()

              this.selectedItem(this.filterQuery, true, true, 'Precios actualizados '+ this.crud.formEditPriceLote.percentage + '%')                                                          
            })
            .catch(error => {                              
              this.modal.formEditPriceLote.active = false 
              
              this.crud.formEditPriceLote.percentage = 0
              this.crud.formEditPriceLote.operations = '+'
              this.crud.formEditPriceLote.filterQuery = []

              loader.hide()

              this.selectedItem(this.filterQuery, true)
              this.$awn.alert(Error.showError(error));
            })              
          }
        })    
        .catch(error => {
          this.$awn.alert(Error.showError(error));          
        })  
      },

      // DESCUENTO LOTE
      showEditDiscountLote() {
        this.modal.formEditDiscountLote.title = "Configuración de descuentos"
        this.modal.formEditDiscountLote.active = true

        this.crud.formEditDiscountLote.discount1 = 0              
        this.crud.formEditDiscountLote.discount2 = 0              
        this.crud.formEditDiscountLote.discount3 = 0              
        this.crud.formEditDiscountLote.filterQuery = []        
      },
      closeEditDiscountLote() {
        this.modal.formEditDiscountLote.active = false        
      },      
      saveEditDiscountLote() {        
        if(isNaN(parseFloat(this.crud.formEditDiscountLote.discount1)) || isNaN(parseFloat(this.crud.formEditDiscountLote.discount2)) || isNaN(parseFloat(this.crud.formEditDiscountLote.discount3))) {
          this.$awn.alert("Revise los valores enviados, deben ser de tipo numérico.");
          return false
        }
        
        this.$bvModal.msgBoxConfirm('¿Continuar con la actualización de descuentos para los productos filtrados?', {
          title: 'Actualización Descuentos',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'ACTUALIZAR',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {          
          if (value) {  
            let loader = this.$loading.show();        
                    
            this.crud.formEditDiscountLote.filterQuery = this.filterQuery
            this.crud.formEditDiscountLote.filterQuery.paginate = false

            var result = serviceAPI.updateProductoDescuentoLote(this.crud.formEditDiscountLote)
                          
            result.then((response) => {                                                                                                                
              this.modal.formEditDiscountLote.active = false   

              this.crud.formEditDiscountLote.discount1 = 0              
              this.crud.formEditDiscountLote.discount2 = 0              
              this.crud.formEditDiscountLote.discount3 = 0              
              this.crud.formEditDiscountLote.filterQuery = []

              loader.hide()

              this.selectedItem(this.filterQuery, true, true, 'Descuentos actualizados')                                                          
            })
            .catch(error => {                              
              this.modal.formEditDiscountLote.active = false 
              
              this.crud.formEditDiscountLote.discount1 = 0              
              this.crud.formEditDiscountLote.discount2 = 0              
              this.crud.formEditDiscountLote.discount3 = 0              
              this.crud.formEditDiscountLote.filterQuery = []

              loader.hide()

              this.selectedItem(this.filterQuery, true)
              this.$awn.alert(Error.showError(error));
            })              
          }
        })    
        .catch(error => {
          this.$awn.alert(Error.showError(error));          
        })          
      },
      
      // PRECIOS DE VENTA
      setPricesProducts(item) {                
        this.tablePrices.items = item.product.prices
        this.itemSelectedPrice = item

        this.modal.formPrice.active = true
        this.modal.formPrice.title = "Precios de Venta"        
      },
      sumDiscountPrice(amount, desc, item = null, action = 'viewCalc') {             
        if(item && item.unit_measurement_initial && item.unit_measurement_result && item.unit_measurement_initial.id != item.unit_measurement_result.id) {        
          if(action == 'viewCalc') {           
            if(parseFloat(item.amount_initial) == parseFloat(amount)) {
              return parseFloat(item.amount_initial)
            } else {
              if(desc) {
                var base1 = (100 - parseFloat(desc)) / 100                
                if(parseFloat(base1) == 0) {                            
                  return parseFloat(amount)
                } else {                              
                  return parseFloat(amount) / base1
                }                        
              } else {            
                return parseFloat(amount)
              }
            }          
          }           
        } else {    
          if(action == 'viewCalc') {        
            if(desc) {
              var base2 = (100 - parseFloat(desc)) / 100                
              if(parseFloat(base2) == 0) {                            
                return parseFloat(amount)
              } else {              
                return parseFloat(amount) / base2
              }                        
            } else {            
              return parseFloat(amount)
            }   
          }         
        }     
      },      
      selectPriceSalesDefault() {              
        this.modal.formPriceSaleDefault.active = true
        this.modal.formPriceSaleDefault.title = "Precio de venta por defecto"        
      },
      loadPriceList(object) {
        if(object){
          this.crud.formPriceSaleDefault.price_list = object
          this.crud.formPriceSaleDefault.price_list_id = object.id             
        } else {
          this.crud.formPriceSaleDefault.price_list = null
          this.crud.formPriceSaleDefault.price_list_id = 0
        }
      },
      savePriceSalesDefault() {        
        this.saveFilterStorage()
        this.restoreFilterStorage()
        this.loadFieldTable()

        this.modal.formPriceSaleDefault.active = false
                
        this.selectedItem(this.filterQuery, true)        
      },  

      // FILTERS
      showFilters() {
        this.table.mostrarFiltros=true
      },
      hideFilters() {
        this.table.mostrarFiltros=false
      },
      loadFilterByWidget() {        
        if( this.$route && 
            this.$route.params && 
            this.$route.params.widget) {
          
          // Filtro Tree          
          let filterTreeSupplierID = this.$route.params.widget.suppliers_id                  
          if(filterTreeSupplierID) {
            this.filters.suppliers = [this.arr.filters.suppliers.find(s => s.code === filterTreeSupplierID)];
            this.loadSuppliers()          
          }          
          
          let filterTableSupplierID = this.$route.params.widget.suppliers_id
          if(filterTableSupplierID) {
            this.filterQuery.suppliers_id = filterTableSupplierID
          }  
          let filterTableCategory = this.$route.params.widget.category
          if(filterTableCategory) {
            this.filterQuery.category = filterTableCategory
          }  
          let filterTableSubcategory = this.$route.params.widget.subcategory
          if(filterTableSubcategory) {
            this.filterQuery.subcategory = filterTableSubcategory
          }  
          let filterTableCode = this.$route.params.widget.code
          if(filterTableCode) {
            this.filterQuery.text = filterTableCode
          }
          let filterTableLastUpdate = this.$route.params.widget.lastUpdate
          if(filterTableLastUpdate) {
            this.filterQuery.lastUpdate = filterTableLastUpdate
          }                       
          this.selectedItem(this.filterQuery,true)

          this.showFilters()
        }                     
      },

      // PRECIOS ACCIONES
      sincroPrice(item) {
        this.$bvModal.msgBoxConfirm('¿Sincronizar precio con lista del proveedor. Producto ['+item.code + '] - ' + item.name + '?', {
          title: 'Sincronizar precio',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'SINCRONIZAR',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'primary',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();        

            var result = serviceAPI.sincroPrice({
              id: item.id
            })
                          
            result.then((response) => {                                                                                                                              
              loader.hide()
            
              if(response.data) {
                this.$awn.success("Precio sincronizado");                    
                this.selectedItem(this.filterQuery, true)
              } else {
                this.$awn.info("El precio actual ya se encuentra actualizado");                                    
              }
            })
            .catch(error => {                                            
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })              
          }
        })    
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      }, 
      undoPrice(item) {
        this.$bvModal.msgBoxConfirm('¿Deshacer precio actual por el anterior en el historial. Producto ['+item.code + '] - ' + item.name + '?', {
          title: 'Deshacer precio',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'warning',
          okTitle: 'DESHACER',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'warning',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();        

            var result = serviceAPI.undoPrice({
              id: item.id
            })
                          
            result.then((response) => {                                                                                                                
              loader.hide()
              this.$awn.success("Precio actualizado");      
              
              this.selectedItem(this.filterQuery, true)
            })
            .catch(error => {                                            
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })              
          }
        })    
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },

      // PARSER HTML
      loadFiscalCondition() {
        var result = serviceAPI.obtenerCondicionesIva()        
        result.then((response) => {
          var data = response.data            
          data.forEach(element => {            
            this.arr.fiscalCondition.push(element)            
          });
        })  
      },
      showParseExtras(item) {
        let html = ""
        
        html = html + "<b><u>COMPOSICION PRECIO DE COSTO</u></b><hr>";
        html = html + "Precio de Lista: <span class='pull-right'> <b>" + Intl.NumberFormat('es-AR',{style:'currency',currency: this.getCurrency }).format(item.price_list) + "</b></span><hr>";

        if(parseFloat(item.additional)>0) {
          html = html + "Adiccional: <span class='pull-right'> + " + Intl.NumberFormat('es-AR',{style:'currency',currency: this.getCurrency }).format(item.additional) + "</span><hr>";  
        }

        if(this.getDiscount(item,1)) {
          html = html + "Descuento 1: <span class='pull-right'> -" + Intl.NumberFormat('es-AR',{style:'percent', minimumFractionDigits: 2, maximumFractionDigits: 2}).format(this.getDiscount(item,1) / 100) + "</span> <br>";  
        }
        if(this.getDiscount(item,2)) {
          html = html + "Descuento 2: <span class='pull-right'> -" + Intl.NumberFormat('es-AR',{style:'percent', minimumFractionDigits: 2, maximumFractionDigits: 2}).format(this.getDiscount(item,2) / 100) + "</span> <br>";
        }
        if(this.getDiscount(item,3)) {
          html = html + "Descuento 3: <span class='pull-right'> -" + Intl.NumberFormat('es-AR',{style:'percent', minimumFractionDigits: 2, maximumFractionDigits: 2}).format(this.getDiscount(item,3) / 100) + "</span> <br>";
        }
        if(this.getDiscount(item,1) || this.getDiscount(item,2) || this.getDiscount(item,3)) {
          html = html + "<hr>";
        }

        if(this.getAdditional(item,1)) {
          html = html + "Adiccional 1: <span class='pull-right'> +" + Intl.NumberFormat('es-AR',{style:'percent', minimumFractionDigits: 2, maximumFractionDigits: 2}).format(this.getAdditional(item,1) / 100) + "</span><br>";  
        }        
        if(this.getAdditional(item,2)) {
          html = html + "Adiccional 2: <span class='pull-right'> +" + Intl.NumberFormat('es-AR',{style:'percent', minimumFractionDigits: 2, maximumFractionDigits: 2}).format(this.getAdditional(item,2) / 100) + "</span><br>";  
        }
        if(this.getAdditional(item,1) || this.getAdditional(item,2)) {
          html = html + "<hr>";
        }    

        if(parseFloat(item.additional_1) > 0) {
          html = html + "Adiccional: <span class='pull-right'> +" + Intl.NumberFormat('es-AR',{style:'currency',currency: this.getCurrency }).format(item.additional_1)  + "</span><hr>";  
        }

        html = html + "Precio de Compra: <span class='pull-right'> <b>" + Intl.NumberFormat('es-AR',{style:'currency',currency: this.getCurrency }).format(item.price_cost) + "</b></span>";

        return html        
      },      
      showParsePriceSales(item) {                        
        let html = ""                
        let utility = item.list.utility
        if(item.utility>0) {
          utility = item.utility
        }
        html = html + "<b>LISTA:</b> " + item.list.name + " (" + item.list.currency.code + ") <br>";          
        html = html + "<b>UTIL:</b> " + utility + "% <b>DESC:</b> " + item.desc + "%<br>";                 
        if(parseFloat(item.unit_measurement_surcharge_equivalence)>0) { 
          html = html + "<hr>";
          html = html + "<b>EQUIVALENCIA:</b> " + item.unit_measurement_surcharge_equivalence + "%";          
        }
        
        return html
      },
      showParsePriceIvaSales(item) {        
        let iva = this.arr.fiscalCondition.find(iva => iva.id === item.products.erp_iva_condition_id);        

        let html = ""                
        html = html + "<b>COND. IVA:</b> " + iva.name + "<hr>";  

        let utility = item.list.utility
        if(item.utility>0) {
          utility = item.utility
        }
        html = html + "<b>LISTA:</b> " + item.list.name + " (" + item.list.currency.code + ") <br>";          
        html = html + "<b>UTIL:</b> " + utility + "% <b>DESC:</b> " + item.desc + "%<br>";   
        if(parseFloat(item.unit_measurement_surcharge_equivalence)>0) {
          html = html + "<hr>";
          html = html + "<b>EQUIVALENCIA:</b> " + item.unit_measurement_surcharge_equivalence + "%";                         
        }
        
        return html
      },
      showParseReferenceExternal(item) {                        
        let html = ""                
        if(item.category && item.subcategory) {
          html = html + "" + item.category + " > " + item.subcategory + "<hr>";                
        }        
        if(item.line && item.subline) {
          html = html + "<b>LINEA:</b> " + item.line + ' <br> ' + item.subline + "<hr>";              
        }                
        html = html + "<u><b>" + item.code + "</b></u> - " + item.name + "<br>";        
        if(item.barcode) {
          html = html + "<hr>" + "<b>CODIGO DE BARRA:</b> <br>" + item.barcode;        
        }
        if(item.reference_external) {
          html = html + "<hr>" + "<b>REFERENCIA EXTERNA:</b> <br>" + item.reference_external;        
        }
        return html
      },     
      showRaisePrice(item) {
        let html = ""
        if(item.history && item.history[1]) {
          if(this.diffLastUpdate(item) <= 15) {
            let _lastUpdateAnt = moment(item.history[1].created_at)          
            let _lastUpdateNow = moment(item.last_update_price)          
            let _diffDays = _lastUpdateNow.diff(_lastUpdateAnt, 'days');
            let titleDays = ""
            if(_diffDays==0) {
              titleDays = "en el mismo día";
            } else if(_diffDays==1) {
              titleDays = "desde el día de ayer"
            } else {
              titleDays = "desde los últimos " + _diffDays + " días"
            }

            let _priceAnt = item.history[1].price_list                    
            let _priceNow = item.price_list              
            let _raise = ((_priceNow - _priceAnt) / _priceAnt) * 100
            let _raiseAbs = Math.abs(_raise)
            let _raisePercentWithDecimal = Intl.NumberFormat('es-AR',{style:'percent', minimumFractionDigits: 2, maximumFractionDigits: 2}).format(_raiseAbs / 100)
            let _raisePercentWithoutDecimal = Intl.NumberFormat('es-AR',{style:'percent', minimumFractionDigits: 0, maximumFractionDigits: 0}).format(_raiseAbs / 100)
                                  
            if(_raise>0) {                        
              let title = "Aumento un " + _raisePercentWithDecimal + " con respecto al precio anterior " + titleDays;
              html = "<span style='font-size:10px; color: darkred; font-weight: bold; cursor: pointer;' title='" + title + "'>" + _raisePercentWithoutDecimal + "&nbsp;<i class='fa fa-level-up'></i></span>";          
            } else {
              let title = "Bajo un " + _raisePercentWithDecimal + " con respecto al precio anterior " + titleDays;
              html = "<span style='font-size:10px; color: darkgreen; font-weight: bold; cursor: pointer;' title='" + title + "'>" + _raisePercentWithoutDecimal + "&nbsp;<i class='fa fa-level-down'></i></span>";          
            }
          }          
        }
        return html;
      },

      // FILTROS SAVE
      saveFilterStorage() {
        Storage.setValue('filter_price_cost_suppliers_products', JSON.stringify(this.crud.formPriceSaleDefault))
      },
      restoreFilterStorage() {
        if(this.hasShowPriceSales) {
          if(Storage.getValue('filter_price_cost_suppliers_products')) {          
            this.crud.formPriceSaleDefault = JSON.parse(Storage.getValue('filter_price_cost_suppliers_products'))
          }         
        } else {
          this.crud.formPriceSaleDefault.price_list = null
          this.crud.formPriceSaleDefault.price_list_id = 0
          this.saveFilterStorage()
        }
      },      
    }       
  }
</script>
<style>
  .crud-price-list-suppliers-products-title {
    font-size: 15px;    
    font-weight: 600;
  }
  .crud-price-list-suppliers-products-code {
    color: gray;
  }
  .crud-price-list-suppliers-table-action button {
    padding: 0px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .crud-price-list-suppliers-table-action i {
    color: #23282c;
  }
  .crud-price-list-suppliers-download {
    float: left;
    margin-right: 10px;    
  }  
  #cost-tree-suppliers .vtl-tree-margin {
    margin-left: 10px;
  }
  #cost-tree-suppliers .vtl-node-main {
    padding: 0px 0 0px 10px;
  }
  #cost-tree-suppliers .vtl-node-content {
    font-size: 10px;
  }
  .cost-supplier-products-history-overflow {
    overflow: auto;    
    max-height: 400px;
  }
  .cost-supplier-products-edit-overflow {
    overflow: auto;
    max-height: 450px;    
  }
  .popover-price-cost-suplliers-products .popover-body hr{
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }   
  .popover-name-cost-suplliers-products .popover-body hr{
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }      
</style>
<style scoped>
  .input-group-sm .input-group-prepend .input-group-text {
    padding: 3px;
  }
  .input-group-sm .input-group-append .btn {
    padding: 1px;
  }  
  .input-group-sm .form-control {
    padding: 2px;
  }    
  .dropdown-divider {    
    margin: 3px;
  }
</style>